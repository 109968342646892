
@import "../../../assets/style.scss";
@import "../../../assets/constants.scss";
@import "../../../assets/fonts.scss";

.TopicCardStyling {

    @include baseCard();
    @include baseBorder();

    width: 18rem;

    flex-direction: column;

    margin: 1rem;

    overflow: hidden;

    cursor: pointer;

}

.TopicCardStyling:hover {
    @include baseShadow();
}

.topicImageHolder {
    position: relative;
    // padding-top: 56.25%;
}

.topicImgCard {
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
}

.topicCardText {
    padding: $BASE_MARGIN*2;
}

.topicCardTitle {
    @include h3();
}

.topicCardDescipt {
    @include h5();
    font-weight: $THIN;

    margin-top: $BASE_MARGIN/2;
}

.AlterContentTopic {
    margin: 0rem $BASE_MARGIN*2 $BASE_MARGIN*2 $BASE_MARGIN*2;
}
