
@import "../../../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../../../../assets/style.scss";

@import "../../../../assets/examGradingOverviewStyle.scss";

.QuestionInExamOverviewView {
    @include overviewItemView();
}

.QuestionInExamOverviewFoldedView {
    @include overviewItemFoldedView();
}

.QuestionInExamOverviewSectionView {
    @include overviewItemSectionView();
}
