
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.Sources {
    @include baseView();

    align-items: flex-start;
    flex-direction: column;

    flex-wrap: wrap;

    width: 100%
}

.SourcesView {
    @include baseView();

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    flex-wrap: wrap;

    width: 100%
}

.SourcsTitle {
    @include baseView();
    @include h2();

    margin: $BASE_MARGIN*3 $BASE_MARGIN $BASE_MARGIN 0rem;
}

.addSource {
    margin: 0rem 0rem 0rem $BASE_MARGIN;
}

.noSources {
    @include h3();
    font-weight: $THIN;
}
