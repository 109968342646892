
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.saveOrDelNewText {
    @include baseView();
    flex-direction: row;
    align-items: flex-end;
}

.saveNewText {
    @include h3();
    font-weight: $NORMAL;

    cursor: pointer;
}

.saveNewText:hover {
    color: $GREEN;
}

.deleteNewText {
    @include h3();
    font-weight: $NORMAL;
    cursor: pointer;

    margin-left: $BASE_MARGIN*1.5;
}

.deleteNewText:hover {
    color: $RED;
}

