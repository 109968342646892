
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../../../assets/style.scss";

.examPrepRowItemExam {
    @include examPrepRowItem();

    color: $DARK_BROWN !important;

}

.examPrepRowItemExam:hover {

    color: $YELLOW !important;

}

.examPrepRowItemTitleExam {
    @include examPrepRowItemTitle();
}
