
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

// locla
@import "../../../../assets/fonts.scss";

.sectTakenExam {
    @include baseView();
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;

    @include h4();
    font-weight: $THIN;

    margin: $BASE_MARGIN 0rem;
}

.sectViewTakenExam {
    @include examsLink();
    @include h4();
    font-weight: $THIN;
}

.sectViewTakenExam:hover {
    @include examsLinkHover();
    @include h4();
    font-weight: $THIN;
}
