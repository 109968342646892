
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

// local
@import "../../assets/examStyles.scss";

.takenExams {
    @include baseExamView();
}

.noTakenExams {

    @include h4();
    font-weight: $NORMAL;

}
