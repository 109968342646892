
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.settingsView {
    @include baseView();
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    width: 100%;
    padding-top: $BASE_MARGIN * 3;
    background-color: $WHITE;
    margin-top: $BASE_MARGIN;
    border-radius: $BORDER_RADIUS;
    padding-bottom: $BASE_MARGIN * 3;
    padding-right: $BASE_MARGIN;

    .profile-pic {
        flex: 0 0 35%;
        max-width: 250px;
        text-align: center;
    }

    .profile-form {
        display: flex;
        flex: 1 0 35%;
        // align-self: flex-end;
        flex-direction: column;
        justify-content: space-between;
        padding-right: $BASE_MARGIN*1.5;

        .settingsUserInput {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        .submit {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: $BASE_MARGIN;
            padding-top: $BASE_MARGIN;

            .submitButtonView {
                display: flex;

                &.cancelButton {
                    background-color: $LIGHT_RED;

                    &:hover {
                        background-color: darken($LIGHT_RED, 10%);
                    }
                }
            }
        }
    }

    .useSettingsFeedbackGenerationWarning {
        display: flex;
        // align-self: flex-end;
        flex-direction: column;
        justify-content: space-between;

        .title {
            display: flex;
            flex-direction: row;
            align-items: left;
            justify-content: center;
        
            @include h4();
            font-weight: $THIN;
        
            width: 100%;
        
            margin-top: $BASE_MARGIN*1/2;
        }

        .subTitle {
            display: flex;
            align-items: center;
            justify-content: left;
        
            @include h4();
            font-weight: $BOLD;
        
            width: 100%;
        
            margin-top: $BASE_MARGIN*2;
        }

        .body {
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: center;
        
            @include h4();
            font-weight: $THIN;
        
            width: 100%;
        
            margin-top: $BASE_MARGIN*1/2;
        }
    }

    @media (max-width: 650px) {
        flex-direction: column;

        .profile-pic {
            margin: auto;
        }

        .profile-form {
            width: 100%;
        }
    }
}
