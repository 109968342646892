
@import "./constants.scss";

// background
body {
    background-image: url(./images/background.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
}

// View
@mixin baseView {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin baseCard {
    display: flex;

    background-color: $WHITE;

    border-radius: $BORDER_RADIUS;
}

@mixin baseContent {
    display: flex;
    @include baseShadow();

    background-color: $WHITE;

    border-radius: $BORDER_RADIUS;
}

// border
@mixin baseBorder {
    border-style: solid;
    border-color: $BORDER_COLOR;
    border-width: $BORDER_WIDTH;
}

// line -- hr
@mixin baseLine {
    width: 100%;
    height: $BORDER_WIDTH;
    border-color: $BORDER_COLOR;

    margin: 0rem;
    padding: 0rem;

    box-sizing: inherit;
}

// shadow
@mixin baseShadow {
    box-shadow: $SHADOW_ALIGN $SHADOW_COLOUR;
}

// rename this or get it out of styles
@mixin addContent {
    @include baseView();
    @include baseContent();

    background-color: $BAIGE;

    padding: 1rem 1rem 1rem 1rem;

    flex-direction: column;

    width: min(95vw, 75rem);

}

@mixin button {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $LIGHT_BROWN;
    }
}