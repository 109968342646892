
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.colorIndicator {
    border-radius: $BORDER_RADIUS $BORDER_RADIUS 0rem 0rem;

    width: 100%;
    height: 2rem;
}

.Green {
    background-color: $GREEN;
}

.LightGreen {
    background-color: $LIGHT_GREEN;
}

.Orange {
    background-color: $YELLOW;
}

.Red {
    background-color: $RED;
}

.LightRed{
    background-color: $LIGHT_RED;
}

.Neutral {
    background-color: $BAIGE;
}
