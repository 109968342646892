
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.Link {
    @include baseView();
    @include baseContent();

    justify-content: flex-start;

    padding: $BASE_MARGIN*2;

    margin: $BASE_MARGIN 0rem;

    align-items: center;

    width: 100%;
}

.LinkTitle {
    @include h4();
    font-weight: $THIN;

    height: fit-content;
    height: 100%;
    flex-grow: 1;
}

.workingLink {
    text-decoration: underline !important;
    cursor: pointer;
}
