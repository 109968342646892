
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

@mixin baseTab {
    margin: $BASE_MARGIN;

    @include h3();
    color: $DARK_BROWN;

    cursor: pointer;

}

.Tab {
    @include baseTab();
}

.Tab:hover {
    color: $YELLOW;
    text-decoration: underline;
}

.selectedTab {
    @include baseTab();
    color: $YELLOW;
    text-decoration: underline;
}
