
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

// local
@import "./assets/style.scss";

.PracticeCards {
    @include baseView();

    align-items: flex-start;
    flex-direction: column;

    width: 100%
}

.titlePracticeCards {
    @include baseView();
    @include h2();

    align-items: center;

    margin: $BASE_MARGIN*3 $BASE_MARGIN $BASE_MARGIN 0rem;
}

.holdLoader {
    margin: 0rem $BASE_MARGIN/2;
}

.filterFlashcards {
    @include SelectionsBase();
    
    padding: $BASE_MARGIN $BASE_MARGIN*2;
    margin-bottom: $BASE_MARGIN;
}

// .filterOptionFlashcards {
//     @include SelectionItemBase();
//     justify-content: flex-start;
// }

// --------------- delete
.PracticeCardsView {
    @include baseView();
    justify-content: flex-start;
    align-items: flex-start;

    flex-direction: row;
}

.PracticeCardsHolder {
    @include baseView();

    justify-content: flex-start;
    align-items: flex-start;

    flex-direction: row;

    flex-wrap: wrap;

    margin: 0rem 0rem 0rem $BASE_MARGIN/2;
}

.emptyList {
    @include baseView();
    @include h3();

    margin: $BASE_MARGIN*2 0rem 0rem $BASE_MARGIN/2;

    font-weight: $THIN;
    color: $YELLOW;
}
