
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";

@mixin CkEditor_base {
        @include baseBorder();
        // @include baseContent();
        
        background-color: $WHITE;
        
        border-radius: $BORDER_RADIUS;
            
        margin: 0rem;
    
        width: 100%;
        height: 100%;
}

.CkEditor {
    @include CkEditor_base();
}

.CkEditorError {
    @include CkEditor_base();
    border-color: $YELLOW;
}

.CkEditorWarning {
    @include CkEditor_base();
    border-color: $YELLOW;
}
