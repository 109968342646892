
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";

.PageListNavigatorView {
    @include baseView();
    width: 100%;

    justify-content: space-between;
}

.PageListNavigatorNotButton {
    @include baseView();
    @include h4();
    color: $BAIGE;
}

.PageListNavigatorButton {
    @include baseView();
    @include h4();

    @include button();

}

.PageListNavigatorPageNumbersView {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;
}

.PageListNavigatorPageNumberMargin {
    margin-right: $BASE_MARGIN/2;
}

.PageListNavigatorPageNumberView {
    @include button();
    font-weight: $BOLD;
}

.PageListNavigatorPageNumberSelectedView {
    color: $YELLOW;
}
