
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

@mixin inputHolderBase {
    @include baseView();
    @include h4();

    flex-direction: column;

    width: 100%;

    margin: 0rem 0rem $BASE_MARGIN 0rem;
}

@mixin inputHolder {
    @include inputHolderBase();

    margin: 0rem 0rem $BASE_MARGIN*3 0rem;
}

@mixin inputTitle {
    @include baseView();
    @include h4();

    justify-content: flex-start;

    width: 100%;

    padding-left: $BASE_MARGIN;

    margin: 0rem 0rem $BASE_MARGIN 0rem;
}

@mixin showError {
    @include baseView();
    @include h5();
    color: $RED;

    justify-content: flex-start;

    width: 100%;

    padding-left: 0.5rem;

    margin: 0rem 0rem 0.2rem 0rem;
}

@mixin showWarning {
    @include baseView();
    @include h5();
    color: $YELLOW;

    justify-content: flex-start;

    width: 100%;

    padding-left: 0.5rem;

    margin: 0rem 0rem 0.2rem 0rem;
}
