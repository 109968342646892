
@import "../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../../assets/style.scss";

.sectExamQuestionPreviewView {
    @include baseView();
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    width: 100%;

    margin: $BASE_MARGIN 0rem;
}

.sectExamQuestionNumber {
    @include baseView();
    @include h3();

    width: 100%;

    justify-content: space-between;
}

.sectExamQuestionType {
    @include h4();
    font-weight: $THIN;
}

.sectExamQuestionPreview {
    @include h4();
    font-weight: $THIN;
    margin: $BASE_MARGIN 0rem;
}
