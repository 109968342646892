
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";
@import "../../assets/style.scss";

.Pointer {
    color: $DARK_BROWN;
    cursor: pointer;
}

.Pointer:hover {
    color: $YELLOW;
}

.Pointer:active {
    color: $DARK_BROWN;
}
