
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.PracticeCardContainer {
    position: relative;

    height: 15rem;
    width: 25rem;

    margin: $BASE_MARGIN*2;
}

.PracticeCardView {
    @include baseView();
    @include baseContent();

    justify-content: flex-start;
    flex-direction: column;

    position: static;

    width: 100%;
    height: 100%;

    left: 0;
    top: 0;

    background-color: $WHITE;

    cursor: pointer;
}

.cardResponse {
    @include baseView();
    @include h2();
    
    margin: $BASE_MARGIN;
    height: 3rem;
}

.cardSelected {
    @include baseView();
    justify-content: center;
    
    @include h2();
    color: $WHITE;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;

    height: 100%;
    width: 100%;

    background-color: $DARK_BROWN;

    border-radius: $BORDER_RADIUS;

    cursor: pointer;

}

.selectedCard {
    opacity: 0.9;
}

.notSelectedCard {
    opacity: 0;
}

.noColorBuffer {
    height: $BASE_MARGIN*1.5;
}
