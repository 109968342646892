
@import "./constants.scss";

@mixin h2 {
    color: $DARK_BROWN;
    font-size: $H2_SIZE;
    font-family: $FONT_TYPE;
    font-weight: $BOLD;
    font-style: normal;
}

@mixin h3 {
    color: $DARK_BROWN;
    font-size: $H3_SIZE;
    font-family: $FONT_TYPE;
    font-weight: $BOLD;
    font-style: normal;
}

@mixin h4 {
    color: $DARK_BROWN;
    font-size: $H4_SIZE;
    font-family: $FONT_TYPE;
    font-weight: $BOLD;
    line-height: $H4_LINE_HEIGHT;
    font-style: normal;
}

@mixin h5 {
    color: $DARK_BROWN;
    font-size: $H5_SIZE;
    font-family: $FONT_TYPE;
    font-weight: $NORMAL;
    line-height: $H5_LINE_HEIGHT;
    font-style: normal;
}
