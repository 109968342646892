
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/constants.scss";

// local
@import "../../assets/constants.scss";
@import "../../assets/style.scss";

.login {
    @include account();
}

// make sure that login is scrolable if screen is not heigh enough
@media only screen and (max-height : 28rem) {
    .login {
        overflow-y: scroll;
    }
}

.loginInput {
    @include baseView();
    @include h4();

    flex-direction: column;

    width: 100%;

    margin: 0rem 0rem $BASE_MARGIN*3 0rem;
}

.loginInputTitle {
    @include baseView();
    @include h4();

    justify-content: flex-start;

    width: 100%;

    padding-left: 0.5rem;

    margin: 0rem 0rem 0.5rem 0rem;
}

.loginInputTitleError {
    @include baseView();
    @include h4();
    // color: $YELLOW;

    justify-content: flex-start;

    width: 100%;

    padding-left: 0.5rem;

    margin: 0rem 0rem 0.5rem 0rem;
}

.showError {
    @include baseView();
    @include h5();
    color: $RED;

    justify-content: flex-start;

    width: 100%;

    padding-left: 0.5rem;

    margin: 0rem 0rem 0.2rem 0rem;
}

.viewPW {
    margin: 0rem 0rem 0rem 0.2rem;

    cursor: pointer;
}

.viewPW:hover {
    margin: 0rem 0rem 0rem 0.2rem;

    color: $YELLOW;

    cursor: pointer;
}

.submit {
    @include baseView();
    
    width: 100%;
    margin: 0rem 0rem 0.5rem 0rem;
}

.forgotPassword {
    @include baseView();
    @include h4();
    font-weight: $THIN;

    margin: $BASE_MARGIN*2 0rem $BASE_MARGIN 0rem;
}

.forgotPassword:hover {

    color: $YELLOW;
    text-decoration: underline;

    cursor: pointer;

}

.oidc-logo{
    max-height:25px;margin-right:10px;
}
