
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";


.profile-image {
  width: 100%; /* Make image fill the container */
  height: auto;
  border-radius: 50%; /* Round the edges */
  max-width: 150px;
  background-color: $DARK_BROWN;
}