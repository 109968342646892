
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";

.AssignmentsAssignmentView {
    @include baseCard();
    @include baseBorder();

    justify-content: space-between;

    @include h4();
    font-weight: $NORMAL;

    width: 100%;

    margin-bottom: $BASE_MARGIN;
    padding: $BASE_MARGIN;

    cursor: pointer;

    &:hover {
        border-color: $YELLOW;
    }
    &:active {
        border-color: $BORDER_COLOR;
    }
}

.AssignmentsAssignmentDeleteCancleView {
    @include baseView();
    flex-direction: column;
}

.AssignmentsAssignmentDelete {
    @include h4();
    font-weight: $NORMAL;
    cursor: pointer;

    &:hover {
        color: $RED;
    }
}

.AssignmentsAssignmentCancleDelete {
    @include h4();
    font-weight: $NORMAL;
    cursor: pointer;

    &:hover {
        color: $YELLOW;
    }
}
