
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.Videos {
    @include baseView();

    align-items: flex-start;
    flex-direction: column;

    flex-wrap: wrap;

    width: 100%
}

.OverViewVideos {
    @include baseView();
    align-items: flex-start;

    flex-wrap: wrap;

    width: 100%
}

.VideosTitle {
    @include baseView();
    @include h2();

    margin: $BASE_MARGIN*3 $BASE_MARGIN $BASE_MARGIN 0rem;
}

.addVideo {
    margin: 0rem 0rem 0rem $BASE_MARGIN;
}

.noVideosWarning {
    @include baseView();
    width: 100%;
    justify-content: flex-start;

    @include h3();

    font-weight: $THIN;
}
