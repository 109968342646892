
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.TopicTitleView {

    @include baseView();

    width: 100%;

    flex-direction: column;

    margin-bottom: $BASE_MARGIN*3;

}

.TopicTitle {

    @include baseView();
    @include h2();

    color: $LIGHT_BROWN;

    padding: $BASE_MARGIN/2;

}

.TopicSubTitle {

    @include baseView();
    @include h3();

    color: $LIGHT_BROWN;

    padding: $BASE_MARGIN/2;

}
