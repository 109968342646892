
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

// local
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.sectExamDtailsView {
    @include sectExamTabView();
}

.sectExamInfoItem {
    @include h4();
    font-weight: $THIN;

    margin: $BASE_MARGIN/2 0rem;
}

.sectExamGrades {
    @include baseView();

    width: 100%;

    justify-content: flex-start;
    align-items: flex-start;
    
    flex-direction: column;

    margin: $BASE_MARGIN*2 0rem $BASE_MARGIN/2 0rem;
}

.sectExamGradesTitle {
    @include baseView();

    justify-content: flex-start;
    align-items: flex-start;

    @include h4();

    margin: $BASE_MARGIN/2 0rem $BASE_MARGIN/2 0rem;

    cursor: pointer;
}

.sectExamGradesTitle:hover {
    color: $YELLOW;
}

.accessResponses {
    @include baseView();

    margin-top: $BASE_MARGIN/2;

    @include h4();
    font-weight: $THIN;
}
