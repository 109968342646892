
@import "../../assets/constants.scss";
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";

.AssignmentsView {

    @include baseView();

    align-items: start;
    flex-direction: column;

    padding: $VIEW_PADDING;

    width: 100%;

}

.AssignemtsTitle {

    @include baseView();
    @include h2();

    align-self: flex-start;

    color: $LIGHT_BROWN;

    padding: $BASE_MARGIN/2;

}

.AssignmentsLoader {
    margin-left: $BASE_MARGIN;
}

.AssignmentsAdd {
    @include baseCard();
    @include baseBorder();

    @include h4();
    font-weight: $THIN;
    
    padding: $BASE_MARGIN/2 $BASE_MARGIN*3;
    margin-left: $BASE_MARGIN;

    cursor: pointer;

    &:hover {
        color: $YELLOW;
        border-color: $YELLOW;
    }
    &:active {
        border-color: $BORDER_COLOR;
    }
}

.AssignmentsNoAssignments {
    @include h4();
    font-weight: $NORMAL;
    padding: $BASE_MARGIN/2 $BASE_MARGIN/2 0rem $BASE_MARGIN/2;
}
