
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.QuestionInfoHolder {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    flex-wrap: wrap;

    flex-direction: row;

    margin: 0rem 0rem $BASE_MARGIN 0rem;

    width: 100%;

}

.QuestionInfoSubHolder {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    padding-right: $BASE_MARGIN*4;
    margin-right: $BASE_MARGIN*4;

    @include baseBorder();
    border-style: none solid none none;
}

.QuestionInfoItem {
    @include h4();
    font-weight: $THIN;

    margin-bottom: $BASE_MARGIN/4;
}
