
@import "../../../assets/constants.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/style.scss";

@mixin assignmentSection {
    @include baseView();
    @include baseCard();
    padding: $BASE_MARGIN;

    width: 100%;

    margin-bottom: $BASE_MARGIN;
}

@mixin assignmentSectionColumns {
    @include assignmentSection();

    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

@mixin assignmentSectionTitle {
    @include baseView();
    width: 100%;

    @include h4();
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    margin-bottom: $BASE_MARGIN/2;
}
