
@import "../../assets/constants.scss";
@import "../../assets/style.scss";

// local
@import "./assets/constants.scss";

.NavBar {
    @include baseView();
    @include baseShadow();

    flex-wrap: nowrap;
    justify-content: space-between;

    background-color: $BAIGE;

    // height: $NAV_BAR_HEIGHT;
    width: $NAV_BAR_WIDTh;

}

.NavButtons {
    @include baseView();
    flex-wrap: nowrap;

    height: 100%;
}

.NavButtonsSect {

    @include baseView();

    height: 100%;

    flex-wrap: wrap;

}

.logo {
    
    height: $NAV_BAR_HEIGHT;
    width: auto;

    margin: $BASE_MARGIN*2;

    cursor: pointer;
    
}

@media (max-width: 1200px) {
    .logo {
        height: 3rem;
    }
}

@media (max-width: 800px) {
    .NavBar {
        justify-content: flex-end;
    }
    .logo {
        display: none;
    }
}
