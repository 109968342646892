
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";
@import "../../assets/constants.scss";

.PlainTextPageView {

    @include baseView();

    padding: $VIEW_PADDING;

    flex-direction: column;

}

.PlainTextPageTitle {

    @include baseView();
    @include h2();

    color: $LIGHT_BROWN;

    padding: $BASE_MARGIN/2;

}

.PlainTextPageTextHolders {

    @include baseView();
    @include baseContent();
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    padding: $BASE_MARGIN*4;

    margin-top: $BASE_MARGIN*3;

}

.PlainTextPageText {
    
    @include baseView();

    flex-direction: column;

    align-items: flex-start;

}
