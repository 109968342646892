
@import "../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../assets/style.scss";

.questionFeedbackReview {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;

    .thumbsUpHolder {
        @include baseView();
        @include baseBorder();
        border-radius: $BORDER_RADIUS 0 0 $BORDER_RADIUS;
        border-right: none;

        padding: $BASE_MARGIN/2 $BASE_MARGIN/2 $BASE_MARGIN/2 $BASE_MARGIN/2;

        cursor: pointer;

        &:hover {
            border-color: $GREEN;
        }
    }
    .thumbsDownHolder {
        @include baseView();
        @include baseBorder();
        border-left: none;

        border-radius: 0 $BORDER_RADIUS $BORDER_RADIUS 0;

        padding: $BASE_MARGIN/2 $BASE_MARGIN/2 $BASE_MARGIN/2 $BASE_MARGIN/2;

        cursor: pointer;

        &:hover {
            border-color: $RED;
        }
    }
}
