
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.addAnswer {
    display: flex;

    @include h4();
    font-weight: $THIN;

    margin: $BASE_MARGIN 0rem 0rem $BASE_MARGIN;

    cursor: pointer;

}

.addAnswer:hover {
    color: $YELLOW;
}

.addAnswer:active {
    color: $DARK_BROWN;
}
