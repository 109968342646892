
@import "../../../assets/constants.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/style.scss";

@mixin baseExamView {
    @include baseView();

    flex-direction: column;
    align-items: flex-start;

    margin: 0rem $VIEW_PADDING $BASE_MARGIN*2 $VIEW_PADDING;

    width: calc(100% - $VIEW_PADDING*2);
}

.examViewUniversal {
    @include baseExamView();

    margin-top: $BASE_MARGIN*2;
}

.examNoQuestionsUniversal {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;
}
