
@import "../../../../../assets/constants.scss";
@import "../../../../../assets/style.scss";
@import "../../../../../assets/fonts.scss";

.EntityInfoView {

    @include baseView();

    margin: $BASE_MARGIN*2 0rem;

    align-items: flex-start;
    justify-content: flex-end;

    width: 100%;

}

.imgEntityInfo {

    @include baseShadow();

    width: 100%;
    height: auto;

    border-radius: $BORDER_RADIUS;

    margin: $BASE_MARGIN*2 0rem;

}

.EntityInfoText {
    width: 79%;

    margin-left: 2%;
}

.EntityInfoImage {
    width: 19%;
}
