
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

@mixin DelQBase {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: $BASE_MARGIN*0.3;

    cursor: pointer;
}

.DelQNormal {
    
    @include DelQBase();

}

.DelQNormal:hover {
    color: $LIGHT_RED;
}

.DelQNormal:active {
    color: $DARK_BROWN;;
}

.cancelDelQ {
    @include DelQBase(); 
}

.cancelDelQ:hover {
    color: $YELLOW;
}

.cancelDelQ:active {
    color: $DARK_BROWN;;
}

.DelQ {
    @include DelQBase(); 
}

.DelQ:hover {
    color: $RED;
}

.DelQ:active {
    color: $DARK_BROWN;;
}

.initDel {
    display: flex;

    justify-content: center;
}
