
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.passwordView {
  @include baseView();
  flex-direction: row;
  align-items: start;

  width: 100%;
  padding-top: $BASE_MARGIN*3;
  background-color: $WHITE;
  margin-top: $BASE_MARGIN;
  border-radius: $BORDER_RADIUS;
  padding-bottom: $BASE_MARGIN*3;
  padding-right: $BASE_MARGIN;
  padding-left: $BASE_MARGIN;
}
.passwordView .profile-pic{
  flex: 0 0 035%;
  max-width: 250px;
  text-align: center;
}
.passwordView .profile-form{
  flex-grow: 1;
}
.passwordView .form-container{
  max-width: 500px;
}

.passwordView  .submit{
  align-items: start;
  justify-content: start;
  }

.passwordUpdateSuccess {
  @include h4();
  font-weight: $NORMAL;
  color: $GREEN;
}

  @media(max-width:650px) {
    .passwordView .profile-pic{
      margin: auto;
    }
    .passwordView{
      flex-direction: column;
    }
   .passwordView .profile-form{
      width: 100%;
    }
    .passwordView .form-container {
      margin: auto;
    }
  }