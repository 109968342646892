
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.GenerateFeedbackLoadingView {
    @include addContent();

    .title {
        @include baseView();
        @include h3();
    }


    .subTitle {
        display: flex;
        align-items: center;
        justify-content: left;
    
        @include h4();
        font-weight: $BOLD;
    
        width: 100%;
    
        margin-top: $BASE_MARGIN*2;
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
    
        @include h4();
        font-weight: $THIN;
    
        width: 100%;
    
        margin-top: $BASE_MARGIN*1/2;
    }

    .marginTop {
        display: flex;
        width: 100%;
        margin-top: $BASE_MARGIN*2;
    }

    .submitButtonView {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: $BASE_MARGIN*2;

        .marginRight {
            margin-right: $BASE_MARGIN;
        }
        .marginLeft {
            margin-left: $BASE_MARGIN;
        }

        &.cancelButton {
            background-color: $LIGHT_RED;

            &:hover {
                background-color: darken($LIGHT_RED, 10%);
            }
        }
    }
}
