
@import "../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../../assets/style.scss";

@mixin DelOptionBase {
    display: flex;
    align-items: right;
    justify-content: right;

    padding-top: $BASE_MARGIN*0.3;

    cursor: pointer;
}

.DelOptionNormal {
    
    @include DelOptionBase();

}

.DelOptionNormal:hover {
    color: $LIGHT_RED;
}

.DelOptionNormal:active {
    color: $DARK_BROWN;;
}

.cancelDelAnswer {
    @include DelOptionBase(); 
}

.cancelDelAnswer:hover {
    color: $YELLOW;
}

.cancelDelAnswer:active {
    color: $DARK_BROWN;;
}

.DelAnswer {
    @include DelOptionBase(); 
}

.DelAnswer:hover {
    color: $RED;
}

.DelAnswer:active {
    color: $DARK_BROWN;;
}

.initDel {
    display: flex;

    justify-content: center;
}

