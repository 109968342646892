
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.cardFrontView {
    @include baseView();
    
    flex-direction: column;

    height: 100%;
    width: 100%;

    padding: $BASE_MARGIN*2;
}

.cardFront {
    @include h3();
    text-align: center;

    width: 80%;
}
