
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

$heightLarge: 20rem;
$widthLarge: 30rem;

@mixin videoBase {
    @include baseView();
    @include baseContent();
    @include baseShadow();

    flex-direction: column;

    align-items: flex-start;
    justify-content: flex-start;

    border-radius: $BORDER_RADIUS;

    margin: $BASE_MARGIN;
    padding: $BASE_MARGIN*2;

}

.videoLarge {
    @include videoBase();
    width: $widthLarge;

}

.videoHolder {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
}

.videoHolder iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoTitle {
    @include h4();
    margin: $BASE_MARGIN 0rem;
}

.videoCreator {
    @include h5();

    font-weight: $THIN;
}

.EditVideoContent {
    @include baseView();

    justify-content: flex-end;

    width: 100%;
    padding: $BASE_MARGIN 0rem;
}
