
@import "../../assets/constants.scss";
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";

.validateMailView {

    @include baseView();
    @include h3();

    padding: $VIEW_PADDING;

    flex-direction: column;

}
