
// local
@import "../../assets/constants.scss";
@import "../../assets/style.scss";

.register {
    @include account();
}

// make sure that login is scrolable if screen is not heigh enough
@media only screen and (max-height : 50rem) {
    .register {
        overflow-y: scroll;
    }
}
