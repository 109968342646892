
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/style.scss";
@import "../../../../../../../../assets/fonts.scss";

@import "../../../../../../assets/style.scss";

.AssignedExamView {
    @include assignmentSectionColumns();
    @include baseBorder();

    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}

.AssignedExamViewSubmitted {
    border-color: $GREEN;
}

.AssignedExamSection {
    @include baseView();
    flex-direction: column;

    align-items: flex-start;
    justify-content: space-between;
}

.AssignedExamSectionLast {
    align-items: flex-end;
}

.AssignedExamToExamButton {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;
    cursor: pointer;

    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $DARK_BROWN;
    }
}

.AssignedExamSubmitted {
    color: $GREEN;
}

.AssignedExamHoldDelete {
    margin-top: $BASE_MARGIN/2;
}
