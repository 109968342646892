
@import "../../assets/style.scss";

.modalHolder {

    position: fixed;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -0%);

    @include baseContent();
    @include baseBorder();
    @include baseShadow();

    max-height: 90%;

    z-index: 200;

}
