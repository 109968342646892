
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.questionFeedbackView {
    @include baseView();
    flex-direction: column;

    @include baseBorder();
    border-radius: $BORDER_RADIUS;

    margin-bottom: $BASE_MARGIN;
    padding: $BASE_MARGIN $BASE_MARGIN $BASE_MARGIN $BASE_MARGIN;

    width: 100%;
}

.questionFeedbackNoPaddingBottom {
    padding-bottom: 0rem;
}

.questionFeedbackTop {
    @include baseView();
    width: 100%;
    justify-content: space-between;

    margin-bottom: $BASE_MARGIN;

}

.questionFeedbackTopLeft {
    @include baseView();
    // width: 100%;

    flex-direction: row;
    justify-content: flex-start;
}

.questionFeedbackRole {
    @include baseView();

    justify-content: start;
    
    @include h4();
    // font-weight: $NORMAL;

    // width: 10%;
    padding-right: $BASE_MARGIN;

}

.questionFeedback {
    width: 100%;
    // margin-top: $BASE_MARGIN;
}

.questionFeedbackText {
    @include baseView();
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @include h4();
    font-weight: $THIN;

    width: 85%;
    margin: $BASE_MARGIN 0rem;

}

.questionFeedbackPoints {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;

    justify-content: center;

    width: 5%;
}

.questionFeedbackEditToggle {
    @include h4();
    font-weight: $NORMAL;

    margin-right: $BASE_MARGIN;
}

.questionFeedbackEditToggle:hover {
    color: $YELLOW;
    cursor: pointer;
}

.questionFeedbackEditToggle:active {
    color: $DARK_BROWN;
    cursor: pointer;
}
