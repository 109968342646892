
@import "../../assets/constants.scss";
@import "../../assets/style.scss";

.TopicView {

    @include baseView();

    padding: $VIEW_PADDING;

    flex-direction: column;

}

.subTopicsList {
    @include baseView();
    justify-content: center;

    width: 100%;

    flex-wrap: wrap;

}
