
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

// local
@import "../assets/style.scss";

.booleanInputView {
    @include inputHolderBase();
    flex-direction: row;
    justify-content: flex-start;
    margin: 0rem;
}

.booleanInputTitle {
    @include h4();
    margin-right: $BASE_MARGIN;
    font-weight: $NORMAL;
}

.booleanInputItemView {
    @include baseView();
    @include h4();

    font-weight: $THIN;

    justify-content: flex-start;
    margin-right: $BASE_MARGIN/4;
}

.booleanInputItemOption {
    cursor: pointer;

    &:hover {
        color: $YELLOW;
    }

    &:active {
        text-decoration: underline;
    }
}

.booleanInputItemSelectedOption {
    color: $YELLOW;
    text-decoration: underline;
}

.booleanInputError {
    color: $RED;
}

.booleanInputShowError {
    @include showError();
    padding-left: 0rem;
}
