
@import "../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../assets/style.scss";
@import "../../../../../../../../../../assets/fonts.scss";

@import "../../../../../../../../assets/style.scss";

.AddAssignmentItemTypeView {
    @include h3();

    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;

    // margin-left: $BASE_MARGIN*2;
    margin-bottom: $BASE_MARGIN;
}
