
@import "../../assets/constants.scss";
@import "../../assets/style.scss";

.pagNavView {

    @include baseView();

    flex-wrap: wrap;

    width: 100%;

}

.pageNavViewStart {
    justify-content: flex-start !important;
}

.pageNavViewEnd {
    justify-content: flex-end !important;
}

.tabs {
    @include baseView();
    flex-wrap: wrap;

    width: 100%;
}

.lineTabs {
    @include baseLine();
}
