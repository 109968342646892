
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.Delete {
    color: $DARK_BROWN;
    cursor: pointer;

    margin: 0rem 0rem 0rem 0rem;
}

.Delete:hover {
    color: $RED;
}

.Delete:active {
    color: $DARK_BROWN;
}

.cancleDelete {
    color: $DARK_BROWN;
    cursor: pointer;

    margin: 0rem 0rem 0rem $BASE_MARGIN/3;
}

.cancleDelete:hover {
    color: $GREEN;
}

.cancleDelete:active {
    color: $DARK_BROWN;
}
