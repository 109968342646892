
@import "../../../../../assets/constants.scss";
@import "../../../../../assets/fonts.scss";
@import "../../../../../assets/style.scss";

.definition {
    @include baseContent();
    justify-content: space-between;
    
    border-radius: $BORDER_RADIUS;

    margin: $BASE_MARGIN*0.75 0rem $BASE_MARGIN*0.75 0rem;
    padding: $BASE_MARGIN*1.5;

    width: 100%;
}

.alterContentDef {
    @include baseView();

    margin: 0rem 0rem 0rem $BASE_MARGIN*2;
}

.definitionFront {
    display: inline;

    @include h4();
}

.definitionBack {
    display: inline;
    @include h4();
    font-weight: $THIN;
}

.creatorDef {
    @include baseView();
    @include h4();
    font-weight: $THIN;
}
