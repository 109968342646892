
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../assets/fonts.scss";

.sectExamInfoView {
    @include baseView();
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    width: 100%;

    padding: $BASE_MARGIN;
}

.sectExamInfoTitle {
    @include baseView();
    @include h2();

    justify-content: space-between;

    width: 100%;

    padding: 0rem $BASE_MARGIN;
}

.sectStartExamInfo {
    @include examsLink();
}
.sectStartExamInfo:hover {
    @include examsLinkHover();
}

.sectExamInfoTitleButtons {
    @include baseView();
}
