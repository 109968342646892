
@import "../../assets/constants.scss";
@import "../../assets/style.scss";

.SubjectView {
    @include baseView();
    justify-content: center;

    padding: $VIEW_PADDING;

    flex-wrap: wrap;
    
}
