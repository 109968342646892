
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.examTitle {
    @include baseView();
    justify-content: flex-end;
    align-items: flex-end;

    flex-wrap: wrap;
}

.titleClickable {
    cursor: pointer;
}

.examTitleText {
    @include h2();
    margin-right: $BASE_MARGIN*2;
}

.editExamTitle {
    @include baseView();
    margin-right: $BASE_MARGIN*2;
}

.examTitlePointer {
    @include h3();
    margin-bottom: $BASE_MARGIN/2;

    font-weight: $THIN;
}

.examTitlePointer:hover {
    color: $YELLOW;
}

.examTitlePointer:active {
    color: $LIGHT_BROWN;
}

.takenExamLoader {
    margin-bottom: $BASE_MARGIN*1.1;
}
