
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.PracticingView {
    @include baseView();
    justify-content: flex-start;
    align-items: center;

    flex-direction: column;

    width: 100%
}

.practicingMenu {
    @include baseView();
    justify-content: space-between;

    width: 100%;
}

@mixin menuText {
    @include h3();
    font-weight: $THIN;
}

.practicingMenuIndex {
    @include menuText();
}

.cancelPractice {
    text-align: left
}

.toResults {
    @include baseView();
    justify-content: flex-end;
    text-align: right;
}

.practicingMenuButton {
    @include menuText();

    width: 10rem;

    cursor: pointer;
}

.practicingMenuButton:hover {
    color: $YELLOW;
}

.practicingCard {
    @include baseView();
    @include baseContent();

    width: 50rem;
    height: 30rem;

    padding: $BASE_MARGIN*2;

    cursor: pointer;
}

.practicingOptions {
    @include baseView();
    @include h2();

    justify-content: space-between;

    width: 50rem;

    padding: $BASE_MARGIN;
}

.practicingCardFront {
    @include h3();
}

.practicingCardBack {
    display: inline-block;
    
    @include h3();
    font-weight: $THIN;
}

.useCorrect {
    color: $GREEN;

    margin-right: $BASE_MARGIN/2;

    cursor: pointer;
}

.useCorrect:hover {
    color: $LIGHT_GREEN;
}

.userIncorrect {
    color: $RED;

    margin-left: $BASE_MARGIN/2;

    cursor: pointer;
}

.userIncorrect:hover {
    color: $LIGHT_RED;
}

.pointersHolder {
    width: 10rem;
}
