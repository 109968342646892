
@import "../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../../assets/style.scss";

.QuestionsStatisticsView {
    @include baseView();
    @include baseBorder();
    border-radius: $BORDER_RADIUS;

    padding: $BASE_MARGIN $BASE_MARGIN $BASE_MARGIN $BASE_MARGIN;
    margin: $BASE_MARGIN 0;

    width: 100%;

    flex-direction: column;
}

.QuestionsStatisticsTitle {
    @include baseView();
    @include h3();

    justify-content: flex-start;
    border-radius: $BORDER_RADIUS;

    margin: $BASE_MARGIN $BASE_MARGIN 0 $BASE_MARGIN*5;

    width: 100%;
}
