
@import "../../../assets/constants.scss";
@import "../../../assets/fonts.scss";

@mixin fontButton {
    @include h4();
}

@mixin fontButtonHover {
    @include h4();
    color: $YELLOW !important;
}

@mixin fontUser {
    @include h4();
    color: $WHITE;
}
