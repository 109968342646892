
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/style.scss";
@import "../../../../../../assets/fonts.scss";

@import "../../../../assets/style.scss";

.sharingAssignmentLinkView {
    @include assignmentSectionColumns();
}

.sharingAssignmentLinkTitle {
    @include assignmentSectionTitle();

    justify-content: space-between;
}

.sharingAssignmentLinkAddButton {
    @include baseView();
    @include h4();

    font-weight: $NORMAL;

    margin-left: $BASE_MARGIN;

    cursor: pointer;

    &:hover {
        color: $YELLOW;
    }

    &:active {
        color: $DARK_BROWN;
    }
}

.sharingAssignmentLinkNoLinks {
    @include h4();
    font-weight: $NORMAL;
}
