
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

// local
@import "../assets/style.scss";

.textInput {
    @include inputHolder();
}

.textInputBase {
    @include inputHolderBase();
}

.textInputTitle {
    @include inputTitle();
}

.showError {
    @include showError();
}
