
@import "../../../assets/constants.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/style.scss";

@mixin examPrepRowItem {
    @include baseView();
    @include h3();
    font-weight: $THIN;

    justify-content: space-between;

    height: 3rem;
    width: 100%;
    padding: $BASE_MARGIN;

    cursor: pointer;
}

@mixin examPrepRowItemHover {
    background-color: $BAIGE;
}

@mixin examPrepRowItemTitle {
    margin-right: $BASE_MARGIN*2;

    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
}

@mixin examPrepRowItemSelected {
    background-color: $BAIGE;
}
