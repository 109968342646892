
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

// local
@import "../assets/style.scss";

.CKTextInput {
    @include inputHolder();
}

.CKTextInputBase {
    @include inputHolderBase();
}

.CKTextInputTitle {
    @include inputTitle();
}

.CKTextshowError {
    @include showError();
}

.CKTextshowWarning {
    @include showWarning();
}
