
@import "../../../../../../assets/style.scss";
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";

.loginWarning {
    @include baseView();
    @include h4();
    color: $RED;

    width: 100%;

    background-color: $BAIGE;

    padding: 1rem 1rem 1rem 1rem;

    flex-direction: column;

    width: min(95vw, 75rem);
}

.loginWarningTitle {
    @include h4();

    font-weight: $THIN;
    color: $RED;
}

.loginWarningMoreInfo {
    display: inline;
    font-style: italic;
    color: $LINK_COLOR;

    cursor: pointer;
}
