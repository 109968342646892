
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.Starred {
    color: $YELLOW;
    cursor: pointer;

    margin: 0rem $BASE_MARGIN/2 0rem 0rem;
}

.Starred:hover {
    color: $DARK_BROWN;
}

// .Star:active {
//     color: $DARK_BROWN;
// }

// ------

.Star {
    color: $DARK_BROWN;
    cursor: pointer;

    margin: 0rem $BASE_MARGIN/2 0rem 0rem;
}

.Star:hover {
    color: $YELLOW;
}

// .Star:active {
//     color: $DARK_BROWN;
// }
