
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.ThemeHolder {
    display: flex;

    flex-direction: column;

    width: 100%;
}

.ThemeTitle {
    @include baseView();
    @include h4();
    justify-content: flex-start;
    width: 100%;

    margin: $BASE_MARGIN*1.5 0rem $BASE_MARGIN/2 0rem;
}

.noEvents {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;
    justify-content: flex-start;
    width: 100%;
    margin: $BASE_MARGIN*1.5 0rem $BASE_MARGIN/2 0rem;
}

.addEvent {
    @include baseView();
    margin-left: $BASE_MARGIN;
}
