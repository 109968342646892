
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

// local
@import "../assets/style.scss";

.imageInput {
    @include inputHolder();

    align-items: flex-start;
}

.imageInputTitle {
    @include inputTitle();
}

.showError {
    @include showError();
}
