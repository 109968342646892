
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

@mixin account { 
    @include baseView();
    @include baseContent();

    background-color: $BAIGE;

    padding: 1rem 1rem 1rem 1rem;

    flex-direction: column;
    
    width: $MODAL_WIDTH;

}
