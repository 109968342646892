
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.assignmentTitle {

    @include baseView();
    @include h2();

    align-self: flex-start;

    color: $LIGHT_BROWN;

    padding: $BASE_MARGIN/2 0rem;

}
