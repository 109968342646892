
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";


textarea {
    @include h5();
    @include baseBorder();
    @include baseCard();

    width: 100%;
    padding: $BASE_MARGIN;
}

input[type="text"],
input[type="date"],
input[type="time"],
input[type="remail"],
input[type="password"] {

    @include h5();
    @include baseBorder();
    @include baseCard();
    
    background-color: $WHITE !important;
    
    border-radius: $BORDER_RADIUS !important;
        
    margin: 0rem !important;

    width: 100%;
    height: 1.8rem;

    padding: 0rem 0.5rem 0rem 0.5rem;
    
}

input[type="text"]:hover,
input[type="date"]:hover,
input[type="time"]:hover,
input[type="remail"]:hover,
input[type="password"]:hover,
input[type="file"]:hover {
    border-color: $YELLOW;
    outline: none;
}


input[type="text"]:focus,
input[type="date"]:focus,
input[type="time"]:focus,
input[type="remail"]:focus,
input[type="password"]:focus,
input[type="file"]:focus {
    border-color: $YELLOW;
    outline: none;
}


input[type="file"] {
    @include baseView();
    @include h5();
    @include baseBorder();
    // @include baseContent();
    
    background-color: $WHITE !important;
    
    border-radius: $BORDER_RADIUS !important;

    width: 100%;
    height: 1.8rem;

    margin: 0.5em 0.5em 0.5em 0.5em;
    padding: 0.4em 0em 0.3em 1em;

    cursor: pointer;
}

// table {
//     @include baseBorder();
// }
// tbody, tr, th, td {
//     @include baseBorder();
// }
