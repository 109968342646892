
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

// local
@import "../../assets/examStyles.scss";

.EditExam {
    @include baseExamView();

    margin-top: $BASE_MARGIN*2;
}

.addQuestion {
    
    display: flex;

    @include h3();
    font-weight: $THIN;

    margin: 0rem 0rem $BASE_MARGIN/2 $BASE_MARGIN;

    cursor: pointer;

}

.addQuestion:hover {

    color: $YELLOW;

}

.addQuestion:active {

    color: $DARK_BROWN;

}
