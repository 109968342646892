
@import "../../../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../../../../assets/style.scss";

@import "../../../../assets/examGradingOverviewStyle.scss";

.ExamInExamOverviewView {
    @include overviewItemView();
}

.ExamInExamOverviewValidatedView {
    border-color: $GREEN;
}

.ExamInExamOverviewFoldedView {
    @include overviewItemFoldedView();
}

.ExamInExamOverviewFoldedHighlighted {
    color: $YELLOW;
    text-decoration: underline;
}

.ExamInExamOverviewSectionView {
    @include overviewItemSectionView();
}

.ExamInExamOverviewFoldedViewValidated {
    color: $GREEN;
}

.ExamInExamOverviewStartCheckingButton {
    @include baseView();

    padding: $BASE_MARGIN;

    justify-content: space-between;


    cursor: pointer;

    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $DARK_BROWN;
    }
}
