
@import "../../assets/constants.scss";
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";

.FooterView {

    @include baseView();

    background-color: $DARK_BROWN;

    padding: $VIEW_PADDING;

    flex-direction: column;

    @include h5();
    font-size: $FOOTER_SIZE;
    color: $BAIGE;
    font-weight: $VERY_THIN;

}
