
@import "../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../../assets/style.scss";

@import "../../assets/examGradingOverviewStyle.scss";

.gradingExamsOverviewTopBarView {
    @include baseView();

    padding: $BASE_MARGIN;

    height: $HEIGHT_GRADING_OVERVIEW_TOPBAR;
    width: 100%;
    justify-content: space-between;

    @include baseBorder();
    border-top: none;
    border-left: none;
    border-right: none;

}

.gradingExamsOverviewTopBarFoldingIntroduction {
    margin-right: $BASE_MARGIN;
    font-weight: $NORMAL;
}

.gradingExamsOverviewTopBarFoldingTitle {
    @include baseView();
    @include h3();

    cursor: pointer;
    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $DARK_BROWN;
    }
}

.gradingExamsOverviewTopBarFoldingLoader {
    margin-left: $BASE_MARGIN;
}

.gradingExamsOverviewTopBarFoldingButton {
    @include h3();
    cursor: pointer;
    
    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $DARK_BROWN;
    }
}
