
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.AssayAnswer {
    display: flex;
    flex-direction: column;

    margin: 0rem;
}

.questionHeader {
    @include h3();

    margin-bottom: $BASE_MARGIN;
}

.holdAnswersQ {
    width: 100%;
}
