
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/style.scss";
@import "../../../../../../assets/fonts.scss";

@import "../../../../assets/style.scss";

.AssignmentItemsView {
    @include assignmentSectionColumns();
}

.AssignmentItemsTitle {
    @include assignmentSectionTitle();
    justify-content: space-between;
    width: 100%
}

.AssignmentItemsAddItem {
    @include baseView();
    @include h4();
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    margin: 0rem $BASE_MARGIN/2 $BASE_MARGIN/2 $BASE_MARGIN/2;
    font-weight: $THIN;

    cursor: pointer;

    &:hover {
        color: $YELLOW;
    }

    &:active {
        color: $DARK_BROWN;
    }
}

.AssignmentItemsNoItems {
    @include baseView();
    @include h4();

    font-weight: $THIN;
}
