
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

// local
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.sectExamInfoDelete {
    @include examsLink();
}

.sectExamInfoDelete:hover {
    @include examsLinkHover();
    color: $RED !important;
}
