
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.events {
    @include baseView();

    align-items: flex-start;
    flex-direction: column;

    width: 100%
}

.EventsTitleTab {
    @include baseView();
    @include h2();

    margin: $BASE_MARGIN*3 $BASE_MARGIN $BASE_MARGIN 0rem;
}

.AddChatagory {
    margin: 0rem 0rem 0rem $BASE_MARGIN;
}

.loadEventsHolder {
    padding: $BASE_MARGIN $BASE_MARGIN $BASE_MARGIN 0rem;
}
