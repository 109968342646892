
@import "../../../../../assets/constants.scss";
@import "../../../../../assets/fonts.scss";
@import "../../../../../assets/style.scss";

@mixin textExplHolder {
    @include baseView();
    @include baseContent();
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    padding: $BASE_MARGIN*4;

    margin-top: $BASE_MARGIN*3;
}
