
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";
@import "../../assets/constants.scss";

.copyToClipboardView {
    @include baseView();

    @include h4();
    font-weight: $NORMAL;

    cursor: pointer;

    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    margin-bottom: $BASE_MARGIN/2;
}
.copyToClipboardView:hover {
    color: $YELLOW;
}

.copyToClipboardViewNoHover {
    @include baseView();

    @include h4();
    font-weight: $NORMAL;

    cursor: pointer;

    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    margin-bottom: $BASE_MARGIN/2;
}

.copyToClipboardTextGreen {
    color: $GREEN;
}

.copyToClipboardTextRed {
    color: $RED;
}
