
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

// local
@import "../assets/style.scss";

.numberInput {
    @include inputHolder();
}

.numberInputAndTitleView {
    @include baseView();
    flex-direction: row;
    width: 100%;
}

.numberInputBase {
    @include inputHolderBase();
}

.numberInputTitle {
    @include inputTitle();
}

.showError {
    @include showError();
}

.showWarning {
    @include showWarning();
}
