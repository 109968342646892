
@import "../../../../../assets/constants.scss";
@import "../../../../../assets/style.scss";
@import "../../../../../assets/fonts.scss";

.Paragraph {
    @include baseCard();

    flex-direction: column;

    width: 100%;

    margin: $BASE_MARGIN*2 0rem;
    padding: $BASE_MARGIN*2;

}

.PTitle {
    @include h3();
}

.PDescription {
    @include h4();

    font-weight: $THIN;
}
