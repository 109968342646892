
@import "../../../../../assets/constants.scss";
@import "../../../../../assets/fonts.scss";
@import "../../../../../assets/style.scss";

.StickyButtonExam {
    @include baseView();
    @include baseCard();
    @include baseShadow();

    @include baseBorder();

    @include h4();
    font-weight: $NORMAL;
    color: $DARK_BROWN !important;

    margin: 0rem $BASE_MARGIN;
    padding: $BASE_MARGIN $BASE_MARGIN*2;

    min-width: $BASE_MARGIN*20;

    cursor: pointer;

    z-index: 50;

    pointer-events: auto;

    &:hover {
        border-color: $YELLOW;
    }

    &:active {
        color: $YELLOW !important;
    }
    
}
