
@import "../../../../assets/style.scss";
@import "../../assets/fonts.scss";
@import "../../assets/constants.scss";

.NavButton {

    @include baseView();
    @include fontButton();

    height: $NAV_ITEM_HEIGHT;

    margin: $NAV_ITEM_MARGIN;

    cursor: pointer;

}

.NavButton:hover {

    @include fontButtonHover();

}
