
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.LinksThemeView {
    display: flex;

    flex-direction: column;

    width: 100%;
}

.LinksThemeTitle {
    @include baseView();
    @include h3();

    font-weight: $THIN;

    justify-content: flex-start;
    width: 100%;

    margin: $BASE_MARGIN*1.5 0rem $BASE_MARGIN/2 0rem;
}
