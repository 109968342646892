
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.loaderRapExamPrep {
    margin-top: $BASE_MARGIN;
}

.examPrepSubjectView {
    @include baseView();
    flex-direction: column;
    align-items: start;

    width: 100%;
}

// will take out when report is implemeted
.ExamPrepTitle {

    @include baseView();
    @include h2();

    align-self: flex-start;

    color: $LIGHT_BROWN;

    padding: $BASE_MARGIN/2;

}

.examPrepPageAddTopic {
    @include baseCard();
    @include baseBorder();

    @include h4();
    font-weight: $THIN;
    
    padding: $BASE_MARGIN/2 $BASE_MARGIN*3;

    cursor: pointer;
}

.examPrepPageAddTopic:hover {
    color: $YELLOW;
    border-color: $YELLOW;
}

.examPrepPageAddTopic:active {
    border-color: $BORDER_COLOR;
}

.examPrepPageAddBook {
    @include baseCard();
    @include baseBorder();

    @include h4();
    font-weight: $THIN;
    
    margin-left: $BASE_MARGIN;
    padding: $BASE_MARGIN/2 $BASE_MARGIN*3;

    cursor: pointer;
}

.examPrepPageAddBook:hover {
    color: $YELLOW;
    border-color: $YELLOW;
}

.examPrepPageAddBook:active {
    border-color: $BORDER_COLOR;
}
