
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

// local
@import "../assets/style.scss";

.selectNumberView {
    @include baseView();
    @include h4();

    width: $BASE_MARGIN*20;

    font-weight: $THIN;

    align-items: flex-end;
    justify-content: flex-start;

    flex-direction: column;
}

.selectNumberTitle {
    // display: flex;
    @include baseView();
}

.selectNumberTitleHover {
    cursor: pointer;

}
.selectNumberTitleHover:hover {
    color: $YELLOW;
}
