
@import "../../../../../assets/constants.scss";
@import "../../../../../assets/fonts.scss";
@import "../../../../../assets/style.scss";

@mixin holdPracticeCards {
    @include baseView();

    justify-content: flex-start;
    align-items: flex-start;

    flex-direction: row;

    flex-wrap: wrap;

    margin: 0rem 0rem 0rem $BASE_MARGIN/2;
}

@mixin SelectionsBase {
    @include baseView();
    @include baseContent();

    justify-content: flex-start;
    align-items: flex-start;

    flex-direction: column;

    width: 15rem;
}

@mixin SelectionItemBase {
    @include baseView();
    @include h4();
    color: $LIGHT_BROWN;

    justify-content: space-between;
    align-items: center;

    height: 1.5rem;
    width: 100%;

    margin: $BASE_MARGIN/2 0rem;

    cursor: pointer;
}
