
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";

.AlertDescription {
    display: flex;
    align-items: center;
    justify-content: left;

    @include h5();
    font-weight: $THIN;

    width: 100%;

    margin-top: $BASE_MARGIN*2;
}
