
@import "../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../../assets/style.scss";

.PointsEditableHolder{
    position: absolute;
    display: inline;
    text-align: right;

    background-color: $WHITE;
    padding: 0rem 0rem $BASE_MARGIN $BASE_MARGIN;

    margin-top: $BASE_MARGIN*3;

}

.pointsOptions {
    cursor: pointer;
    
    padding-bottom: $BASE_MARGIN/2;
}

.pointsOptions:hover {
    color: $YELLOW;
}
