
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../assets/style.scss";

.PracticeCardsView {
    @include baseView();
    justify-content: flex-start;
    align-items: flex-start;

    flex-direction: row;
}

.PracticeCardsHolder {
    @include holdPracticeCards();
}


.emptyList {
    @include baseView();
    @include h3();

    margin: $BASE_MARGIN*2 0rem 0rem $BASE_MARGIN/2;

    font-weight: $THIN;
    color: $YELLOW;
}

.OverViewFilters {
    @include baseView();
    flex-direction: column;

    margin: $BASE_MARGIN*2 0rem;
}

.practiceCardsOverviewFilterView {
    @include baseView();
    flex-direction: column;
}

.selectFlashcards {
    @include SelectionsBase();
    
    padding: $BASE_MARGIN $BASE_MARGIN*2;
    margin-bottom: $BASE_MARGIN;
}

.selectFlashcardsText {
    @include SelectionItemBase();
    @include baseView();
    
    height: 1.5rem;
    width: 100%;

    justify-content: flex-start;
    align-items: center;
}

.practiceButton {
    @include SelectionsBase();
    @include h4();

    padding: $BASE_MARGIN;

    justify-content: center;
    align-items: center;

    cursor: pointer;
}
