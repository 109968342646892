
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../assets/style.scss";

.textExplenationHolder {
    @include textExplHolder();
}

.AlterContentEdit {
    @include baseView();

    justify-content: flex-end;
    width: 100%;
    height: 100%;
}
