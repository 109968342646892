
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.SettingsInputView {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .inputView {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .submitButtonView {
        display: flex;
        margin-top: $BASE_MARGIN*2;

        .marginRight {
            margin-right: $BASE_MARGIN;
        }
        .marginLeft {
            margin-left: $BASE_MARGIN;
        }

        &.cancelButton {
            background-color: $LIGHT_RED;

            &:hover {
                background-color: darken($LIGHT_RED, 10%);
            }
        }
    }
}
