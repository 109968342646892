
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.DelQConfermation {

    @include addContent();
    
}

.DelQConfermationTitle {

    @include h3();
    
}

.DelQConfermationSubTitle {

    width: 100%;

    @include h5();
    margin-top: $BASE_MARGIN;

}

.DelQConfermationUserOptions {

    @include baseView();

    padding: $BASE_MARGIN*2 0 $BASE_MARGIN 0;

}

.DelQConferm {
    
    justify-content: center;

    @include h4();
    font-weight: $THIN;

    @include baseCard();
    @include baseBorder();

    padding: $BASE_MARGIN $BASE_MARGIN*1.5;
    margin-right: $BASE_MARGIN;

    width: 250px;

    cursor: pointer;

}

.DelQConferm:hover {
    color: $RED;
    border-color: $RED;
}

.DelQConferm:active {

    font-weight: $NORMAL;

}

.DelQConfermationCancle {
    
    justify-content: center;

    @include h4();
    font-weight: $THIN;

    @include baseCard();
    @include baseBorder();

    padding: $BASE_MARGIN $BASE_MARGIN*1.5;
    margin-left: $BASE_MARGIN;

    width: 250px;

    cursor: pointer;

}

.DelQConfermationCancle:hover {
    border-color: $YELLOW;
}

.DelQConfermationCancle:active {
    color: $YELLOW;

    border-color: $YELLOW;
}
