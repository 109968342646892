
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";
@import "../../assets/style.scss";

.FiltersView {
    @include baseView();
    width: 100%;
}

.selectionMenu {
    
    // -- @include SelectionsBase();
    @include baseView();
    @include baseContent();

    justify-content: flex-start;
    align-items: flex-start;

    flex-direction: column;

    width: 15rem;
    padding: $BASE_MARGIN $BASE_MARGIN*2;
    margin-bottom: $BASE_MARGIN;
}

.selectionText {
    // -- @include SelectionItemBase();
    @include baseView();
    @include h4();
    color: $LIGHT_BROWN;

    justify-content: space-between;
    align-items: center;

    height: 1.5rem;
    width: 100%;

    margin: $BASE_MARGIN/2 0rem;

    cursor: pointer;

    // --
    @include baseView();
    
    height: 1.5rem;
    width: 100%;

    justify-content: flex-start;
    align-items: center;
}
