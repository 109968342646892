
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";

.AddAssignment {
    @include addContent();
}

.AddAssignmentExtraInfo {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;

    justify-content: flex-start;
    width: 100%;

    margin-bottom: $BASE_MARGIN*2;
}
