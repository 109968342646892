
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.assignmentInfoView {
    @include baseView();
    width: 100%;

    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    padding-top: $BASE_MARGIN;
}

.assignmentInfoSectionView {
    @include baseView();
    width: 100%;

    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
}

.assignmentInfoTopView {
    @include baseView();
    width: 100%;

    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    margin-bottom: $BASE_MARGIN;
}

.assignmentInfoTopViewLeft {
    margin-right: $BASE_MARGIN/2;
}

.assignmentInfoTopViewRight {
    margin-left: $BASE_MARGIN/2;
}

.assignmentInfoItem {
    @include h4();
    font-weight: $NORMAL;
}
