
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";
@import "../../assets/style.scss";

.breadCrumbs {
    @include baseView();

    justify-content: flex-start;
    align-items: center;

    flex-wrap: wrap;

    width: 100%;
}

.breadCrumbArrow {
    @include h4();
    font-weight: $THIN;
}

.breadCrumbPlain {
    @include h4();
    font-weight: $THIN;

    margin: $BASE_MARGIN;
}

.breadCrumb {
    @include h4();
    font-weight: $THIN;

    margin: $BASE_MARGIN;
    cursor: pointer;
}

.breadCrumb:hover {
    color: $GREEN;
}
