
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";
@import "../../assets/constants.scss";

.accountTitle {
    @include baseView();
    @include h3();
    color: $YELLOW;

    width: 100%;

    margin: 0rem 0rem $BASE_MARGIN*3 0rem;
}
