
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";

.inputError {
    border-color: $RED !important;
}

.inputWarning {
    border-color: $YELLOW !important;
}

.textColorError {
    color: $RED !important;
}
