
@import "../../../../../assets/constants.scss";
@import "../../../../../assets/fonts.scss";

@mixin examsLink {
    @include baseView();
    @include h3();

    font-weight: $THIN;

    color: $YELLOW !important;

    margin: 0rem $BASE_MARGIN*3 0rem $BASE_MARGIN*3;

    cursor: pointer;
}

@mixin examsLinkHover {
    @include examsLink();
    color: $GREEN !important;
}
