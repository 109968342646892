
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";
@import "../../assets/style.scss";

.Add {
    color: $DARK_BROWN;
    cursor: pointer;
}

.Add:hover {
    color: $YELLOW;
}

.Add:active {
    color: $DARK_BROWN;
}
