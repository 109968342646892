
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.QuestionStatisticsView {
    @include baseView();
    @include baseCard();
    width: 100%;

    margin: 0 $BASE_MARGIN*2 $BASE_MARGIN*2 $BASE_MARGIN*2;
    padding: $BASE_MARGIN*2;
}

.QuestionStatisticsNoQuestions {
    @include h4();
    font-weight: $NORMAL;
}

.QuestionsStatisticsNavigatorView {
    @include baseView();

    margin: $BASE_MARGIN*2 0;

    flex-direction: column;
    width: 100%;
}
