
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../assets/style.scss";

.addNewTextHolder {
    @include textExplHolder();
}

.InTextSaveNewText {
    margin: $BASE_MARGIN $BASE_MARGIN $BASE_MARGIN 0rem;
}
