
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../../../assets/examStyles.scss";

.assignedExamStatisticsView {
    @include baseView();
    @include baseCard();
    width: 100%;

    margin: $BASE_MARGIN*2;
}
