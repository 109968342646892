
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.cardBackView {
    @include baseView();
    display: inline-block;
    overflow-y: scroll;
    
    flex-direction: column;

    height: 100%;
    width: 100%;

    padding: $BASE_MARGIN*2;
}

.cardBack {
    @include h4();
    font-weight: $THIN;
}
