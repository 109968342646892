
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.QuestionImageHolder {
    @include baseView();
    justify-content: flex-start;
    align-items: flex-start;

    flex-direction: column;

    margin-bottom: $BASE_MARGIN;
}

.ReplaceImage {
    @include h3();
    font-weight: $THIN;

    cursor: pointer;
}
.ReplaceImage:hover {
    color: $YELLOW;
}
.ReplaceImage:active {
    color: $DARK_BROWN;
}

.QuestionImageStyle {
    max-height: 60vh;
    max-width: 90%;

    height: auto;
    width: auto;

    cursor: pointer;
}

.QuestionImageFullScreen {
    
    min-height: 50vh;
    
    width: auto;
    height: auto;

    max-width: 90vw;
    max-height: 90vw;

    border-radius: $BORDER_RADIUS;

    cursor: pointer;
}
