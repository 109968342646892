
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../../../assets/examStyles.scss";

.assignedExamView {
    @include baseView();
    width: 100%;
}
