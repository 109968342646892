
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.examInfoView {
    @include baseView();
    @include baseCard();

    @include baseShadow();

    padding: $BASE_MARGIN 0;
    margin: $BASE_MARGIN 0rem $BASE_MARGIN*2 0rem;

    @include h3();
    font-weight: $THIN;

    flex-direction: row;
    align-items: stretch;
}

.examInfoSection {
    padding: $BASE_MARGIN*2 $BASE_MARGIN*8 $BASE_MARGIN*2 $BASE_MARGIN*3;
}

.borderExamInfoSection {
    @include baseBorder();
    border-style: none solid none none;
}

.examInfoItemView {
    margin: $BASE_MARGIN/3 0rem;
}

.examInfoItemClickable {
    cursor: pointer;
}

.examInfoItemClickable:hover {
    color: $YELLOW;
}

.examInfoItemClickable:active {
    color: $DARK_BROWN;
}

.examinfoItemSelected {
    color: $YELLOW;
}

.examInfoItemViewTitle {
    font-weight: $BOLD;
}

.ExamInfoAssignedExamDeadlineRed {
    color: $RED;
}
