
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

// design both
.checkAnswer {
    @include baseView();
    width: 100%
}

.checkAnswerOption {
    @include baseView();
    @include baseBorder();

    @include h3();

    height: $BASE_MARGIN*5;
    width: $BASE_MARGIN*20;

    // padding: $BASE_MARGIN $BASE_MARGIN*8;

    cursor: pointer;
}

// base
@mixin correct {
    border-right: none;
    border-radius: $BORDER_RADIUS 0 0 $BORDER_RADIUS;
}

@mixin incorrect {
    border-left: none;
    border-radius: 0 $BORDER_RADIUS $BORDER_RADIUS 0;
}

.baseCorrect {
    @include correct();
}

.baseInCorrect {
    @include incorrect();
}

// correct nothing
.checkAnswerCorrect {
    @include correct();
}
.checkAnswerCorrect:hover {
    color: $GREEN;
    border-color: $GREEN;
}
.checkAnswerCorrect:active {
    color: $LIGHT_GREEN;
    border-color: $LIGHT_GREEN;
}

// not correct or incorrect
.answerCorrectBorder {
    @include correct();
    border-color: $GREEN;
}
.answerCorrectBorder:hover {
    color: $GREEN;
    border-color: $GREEN;
}
.answerCorrectBorder:active {
    color: $LIGHT_GREEN;
    border-color: $LIGHT_GREEN;
}

// answer correct
.checkAnswerUserCorrect {
    border-right: none;
    border-radius: $BORDER_RADIUS 0 0 $BORDER_RADIUS;

    background-color: $LIGHT_GREEN;
    border-color: $LIGHT_GREEN;
}
.checkAnswerUserCorrect:hover {
    color: $LIGHT_BROWN;
    border-color: $BORDER_COLOR;
}
.checkAnswerUserCorrect:active {
    color: $RED;
    border-color: $RED;
}

// incorrect nothing
.checkAnswerIncorrect {
    @include incorrect();
}

.checkAnswerIncorrect:hover {
    color: $RED;
    border-color: $RED;
}

.checkAnswerIncorrect:active {
    color: $LIGHT_RED;
    border-color: $LIGHT_RED;
}

// not correct or incorrect
.answerIncorrectBorder {
    @include incorrect();
    border-color: $RED;
}
.answerIncorrectBorder:hover {
    color: $RED;
    border-color: $RED;
}
.answerIncorrectBorder:active {
    color: $LIGHT_RED;
    border-color: $LIGHT_RED;
}

// answer incorrect
.checkAnswerUserIncorrect {
    border-left: none;
    border-radius: 0 $BORDER_RADIUS $BORDER_RADIUS 0;

    background-color: $LIGHT_RED;
    border-color: $LIGHT_RED;
}

.checkAnswerUserIncorrect:hover {
    color: $LIGHT_BROWN;
    border-color: $BORDER_COLOR;
}

.checkAnswerUserIncorrect:active {
    color: $GREEN;
    border-color: $GREEN;
}
