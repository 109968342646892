
@import "../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../../assets/style.scss";

@import "../../assets/examGradingOverviewStyle.scss";

.UnfoldedOverviewView {
    @include baseView();
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: calc(100% - $HEIGHT_GRADING_OVERVIEW_TOPBAR);
    width: 100%;
}

.assignedExamOverviewFiltersView {
    @include baseView();
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex: 0 0 auto;
}

.UnfoldedOverviewExamsView {
    @include baseView();
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;

    overflow-y: auto;
    overflow-x: hidden;

    // margin-bottom: $BASE_MARGIN;
    padding-bottom: $BASE_MARGIN;
}

.UnfoldedOverviewExamsNoExams {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;
    margin: $BASE_MARGIN;
}
