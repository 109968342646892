
@import "../../../../../assets/constants.scss";
@import "../../../../../assets/fonts.scss";
@import "../../../../../assets/style.scss";

.examFeedbackItemView {
    @include baseView();
    @include baseCard();

    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin-top: $BASE_MARGIN;

    width: 100%;
}

.examFeedbackItemInputView {
    @include baseView();
    @include baseCard();

    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
}

.examFeedbackItemInputButtonsView {
    @include baseView();
    @include baseCard();

    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
}

.examFeedbackItemInputComentRoleView {
    @include baseView();
    width: 100%;
    justify-content: flex-start;
}

.examFeedbackItemInputButton {
    @include h3();

    font-weight: $THIN;

    cursor: pointer;

    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $DARK_BROWN;
    }
}

.examFeedbackItemInputComentTitle {
    @include baseView();
    
    justify-content: flex-start;
    align-items: flex-start;

    @include h4();
}

.examFeedbackItemInputComentTitleLoader {
    @include h4();
    margin-left: $BASE_MARGIN
}

.examFeedbackItemInputComentBody {
    @include h3();
    font-weight: $THIN;

    margin: 0rem;
}

.examFeedbackItemInputComentEdit {
    @include h4();
    font-weight: $THIN;

    cursor: pointer;

    margin-left: $BASE_MARGIN;

    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $DARK_BROWN;
    }
}

.examFeedbackItemInputComentDelete {
    @include h4();
    font-weight: $THIN;

    cursor: pointer;

    margin-left: $BASE_MARGIN;

    &:hover {
        color: $RED;
    }
    &:active {
        color: $DARK_BROWN;
    }
}
