
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";

.numberError {
    border-color: $RED !important;
}

.numberWarning {
    border-color: $YELLOW !important;
}

.notNumberError {
    color: $RED !important;
}
