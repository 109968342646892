
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.questionHeaderInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @include h4();
    font-weight: $THIN;
}

.showDelOptions {
    justify-content: center;
    margin-bottom: $BASE_MARGIN*0.5;
    padding: 0rem 0rem% 0rem 0rem;
}
