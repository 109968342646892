
@import "../../assets/constants.scss";
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";

.closeLight {
    @include h4();

    color: $WHITE;
    cursor: pointer;
}

.closeLight:hover {
    color: $YELLOW;
}

.closeLight:active {
    color: $WHITE;
}

// dark
.closeItem {
    @include h4();

    color: $DARK_BROWN;
    cursor: pointer;
}

.closeItem:hover {
    color: $YELLOW;
}

.closeItem:active {
    color: $DARK_BROWN;
}
