
// nav bar
$NAV_BAR_HEIGHT: 5rem;
$NAV_BAR_WIDTh: 100%;

// nav items
$NAV_ITEM_HEIGHT: 80%;
$NAV_ITEM_MARGIN: 1rem 1.5rem 1rem 1.5rem;

// modal
$MODAL_WIDTH: 20rem;
