
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../assets/style.scss";

.filterOptionFlashcard {
    @include SelectionItemBase();
}

.filterOptionFlashcardsInfo {
    @include baseView();
    
    height: 1.5rem;
    width: 100%;

    justify-content: flex-start;
    align-items: center;
}
