
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";

.ExamNav {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    flex-wrap: nowrap;

    width: 100%;
}

.ExamViews {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
}
