
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.AutogradingPermissionsView {
    @include addContent();
}

.AutogradingPermissionsButtons {
    
    display: flex;
    justify-content: space-around;

    width: 100%;

    margin-top: $BASE_MARGIN*2;
}

.AutogradingPermissionSubTitle {
    display: flex;
    align-items: center;
    justify-content: left;

    @include h4();
    font-weight: $BOLD;

    width: 100%;

    margin-top: $BASE_MARGIN*2;
}

.AutogradingPermissionsBody {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;

    @include h4();
    font-weight: $THIN;

    width: 100%;

    margin-top: $BASE_MARGIN*1/2;
}

.AutogradingPermissionsBodyRowDirection {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: center;

    @include h4();
    font-weight: $THIN;

    width: 100%;

    margin-top: $BASE_MARGIN*1/2;
}
