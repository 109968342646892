
@import "../../assets/constants.scss";
@import "../../assets/style.scss";

.ExamRouting {

    @include baseView();
    flex-direction: column;
    width: 100%;

}
