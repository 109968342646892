
@import "../../assets/constants.scss";
@import "../../assets/style.scss";

.SubTopicView {

    @include baseView();

    padding: $VIEW_PADDING;

    flex-direction: column;

}
