
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

// local
@import "../assets/style.scss";

.selectInput {
    @include inputHolder();
}

.selectInputBase {
    @include inputHolderBase();
}

.selectInputTitle {
    @include inputTitle();
}

.selectShowError {
    @include showError();
}

.selectShowErrorP2 {
    @include showError();

    color: $YELLOW;
}
