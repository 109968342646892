
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.Source {
    @include baseView();
    @include baseContent();

    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;

    padding: $BASE_MARGIN*2;
    margin: $BASE_MARGIN 0rem;

    width: 100%;
}

.SourceTitle {
    @include baseView();

    justify-content: space-between;
    width: 100%;

    @include h3();
    margin: $BASE_MARGIN/2 0rem;
}

.sourceTitleText {
    display: inline
}

.lineSource {
    @include baseLine();
}

.sourceInfo {
    @include baseView();

    align-items: flex-start;
    justify-content: space-between;

    width: 100%;

    padding: $BASE_MARGIN*2 0rem $BASE_MARGIN*2 0rem;
}

.imgSource {
    width: 30%;
    height: auto;
}

.analysis {
    @include baseView();
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;


    @include h3();
    font-weight: $THIN;

    width: 69%;

}

.toggleShowAnalysis {
    @include baseView();
    @include baseBorder();
    @include h4();

    font-weight: $THIN;

    border-radius: $BORDER_RADIUS;
    
    padding: $BASE_MARGIN;
    margin: 0rem $BASE_MARGIN $BASE_MARGIN 0rem;

    cursor: pointer;
}

.toggleShowAnalysis:hover {
    color: $YELLOW;
    border-color: $YELLOW;
}

.sourceVideoHolder {

    margin-right: $BASE_MARGIN;

    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
}

.sourceVideoHolder iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

