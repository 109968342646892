
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.InvalidExam {

    @include addContent();

}

.InvalidExamTitle {

    @include h3();

}

.InvalidExamError {

    width: 100%;

    @include h5();
    color: $RED;
    margin-top: $BASE_MARGIN;

}

.InvalidExamWarning {

    width: 100%;

    @include h5();
    color: $YELLOW;
    margin-top: $BASE_MARGIN;

}

.InvalidExamOptions {
    @include baseView();

    padding: $BASE_MARGIN*2 0 $BASE_MARGIN 0;
}

.InvalidExamProceed {
    
    justify-content: center;

    @include h4();
    font-weight: $THIN;

    @include baseCard();
    @include baseBorder();

    padding: $BASE_MARGIN $BASE_MARGIN*1.5;
    margin-right: $BASE_MARGIN;

    width: 250px;

    cursor: pointer;

}

.InvalidExamProceed:hover {
    color: $YELLOW;
    border-color: $YELLOW;
}

.InvalidExamProceed:active {

    font-weight: $NORMAL;

}

.InvalidExamCancle {
    
    justify-content: center;

    @include h4();
    font-weight: $THIN;

    @include baseCard();
    @include baseBorder();

    padding: $BASE_MARGIN $BASE_MARGIN*1.5;
    margin-left: $BASE_MARGIN;

    width: 250px;

    cursor: pointer;

}

.InvalidExamCancle:hover {
    border-color: $YELLOW;
}

.InvalidExamCancle:active {
    color: $YELLOW;

    border-color: $YELLOW;
}

