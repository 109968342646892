
@import "../../assets/constants.scss";
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";

.thumbs {
    @include h4();
    cursor: pointer;
    
    &:hover {
        color: $YELLOW;
    }
    
    &.down:hover {
        color: $RED;
    }
    &.up:hover {
        color: $GREEN;
    }

    &:active, &.down:active, &.up:active {
        color: $DARK_BROWN;
    }
}
