
@import "../../assets/style.scss";
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";

.graphView {
    @include baseView();
    @include baseCard();

    flex-direction: column;
    width: 100%;

    margin: $BASE_MARGIN*2;
}

.graphTitle {
    @include h2();
    margin: 0 $BASE_MARGIN $BASE_MARGIN $BASE_MARGIN*2;
    width: 100%;
}
