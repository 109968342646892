
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.examsView {
    @include baseView();

    align-items: flex-start;
    flex-direction: column;

    width: 100%
}

.examsTitle {
    @include baseView();
    @include h2();

    align-items: center;

    margin: $BASE_MARGIN*3 $BASE_MARGIN $BASE_MARGIN 0rem;
}

.noExamsWarning {
    @include baseView();
    @include h3();

    font-weight: $THIN;
}
