
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

// local
@import "../assets/style.scss";

.textareaInputView {
    @include inputHolder();
}

.textareInputBaseView {
    @include inputHolderBase();
}

.textareInputTitle {
    @include inputTitle();
}

.textareShowError {
    @include showError();
}
