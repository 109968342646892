
@import "../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../../assets/style.scss";

.AssignedExamFilterView {
    @include baseView();
    @include baseContent();

    justify-content: flex-start;
    align-items: flex-start;

    flex-direction: column;

    width: 15rem;

    padding: $BASE_MARGIN $BASE_MARGIN*2;
    margin: $BASE_MARGIN $BASE_MARGIN $BASE_MARGIN $BASE_MARGIN;
}
