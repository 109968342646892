
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";
@import "../../assets/style.scss";

.resetPasswordView {

    @include baseView();

    padding: $VIEW_PADDING;

    flex-direction: column;

}

.resetPasswordTitle {
    @include baseView();
    @include h2();

    align-items: center;

    margin: $BASE_MARGIN*3 $BASE_MARGIN $BASE_MARGIN 0rem;
}

.resetPasswordSent {
    @include h3();
    font-weight: $THIN;

    cursor: default;
}
