
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.questionNumber {
    display: flex;

    cursor: pointer;

    padding: $BASE_MARGIN*0.7 $BASE_MARGIN $BASE_MARGIN 0rem;
}

.questionNumber:hover {
    color: $YELLOW;
}

.questionNumber:active {
    color: $DARK_BROWN;
}

.QuestionInfoPointer {
    display: flex;
    padding-top: $BASE_MARGIN*0.3;
    
    margin-left: $BASE_MARGIN;

}
