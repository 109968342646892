
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";
@import "../../assets/style.scss";

.AlterContent {
    @include baseView();

    justify-content: flex-start;

    margin: 0rem 0rem 0rem $BASE_MARGIN/2;
}
