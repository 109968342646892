
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";
@import "../../assets/style.scss";

.deleteView {
    @include baseCard();

}

.deleteButton {
    @include baseCard();
    @include baseBorder();

    @include h4();
    font-weight: $THIN;
    
    padding: $BASE_MARGIN/2 $BASE_MARGIN*3;

    cursor: pointer;
}

.confirmDelete {
    @include baseCard();
    @include baseBorder();

    @include h4();
    font-weight: $THIN;
    
    padding: $BASE_MARGIN/2 $BASE_MARGIN*3;

    cursor: pointer;

    &:hover {
        color: $RED;
        border-color: $RED;
    }

    &:active {
        border-color: $BORDER_COLOR;
    }
}

.deleteButton:hover {
    color: $YELLOW;
    border-color: $YELLOW;
}

.deleteButton:active {
    border-color: $BORDER_COLOR;
}

.deleteButtonMarginLeft {
    margin-left: $BASE_MARGIN;
}
