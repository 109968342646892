
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.CreatorsView {
    @include baseView();
    flex-direction: column;

    width: 100%;

    padding-top: $BASE_MARGIN*2;
}

li {
    @include h4();
    font-weight: $THIN;
    line-height: 1.4rem;
}
