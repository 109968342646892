
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.feedbacksView {
    @include baseView();
    
    width: 100%;

    flex-direction: column;
}

.feedbacksTitleView {
    @include baseView();
    width: 100%;

    flex-direction: row;
    justify-content: space-between;

}

.feedbacksTitleViewTitle {
    @include baseView();
    // width: 100%;

    flex-direction: row;
    justify-content: flex-start;

}

.feedbacksTitle {
    @include baseView();
    @include h3();

    justify-content: flex-start;

    margin: 0rem 0rem $BASE_MARGIN 0rem;
}

.feedbacksShowAllButton {
    @include h4();
    font-weight: $NORMAL;

    cursor: pointer;
}

.feedbacksShowAllButton:hover {
    color: $YELLOW;
    cursor: pointer;
}

.feedbacksShowAllButton:active {
    color: $DARK_BROWN;
    cursor: pointer;
}

.feedbacksToShow {
    @include h4();
    font-weight: $NORMAL;

    margin-left: $BASE_MARGIN;
}

.feedbacksToShow:hover {
    color: $YELLOW;
    cursor: pointer;
}

.feedbacksToShow:active {
    color: $DARK_BROWN;
    cursor: pointer;
}

.feedbacksNewFeedbackButton {
    @include baseView();
    width: 100%;
    justify-content: flex-start;

    margin-top: $BASE_MARGIN*0.5;

    @include h4();
    font-weight: $NORMAL;
}

.feedbacksNewFeedbackButton:hover {
    color: $YELLOW;
    cursor: pointer;
}

.feedbacksNewFeedbackButton:active {
    color: $DARK_BROWN;
    cursor: pointer;
}
