
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

// local
@import "../../../../../../assets/examStyles.scss";

.assignedExamOverviewView {
    @include baseView();
    @include baseBorder();
    @include baseShadow();
    @include baseCard();
    
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;

    border-radius: 0px $BORDER_RADIUS $BORDER_RADIUS 0px;

    position: fixed; // Make the div fixed to the side of the screen
    left: 0; // Align it to the left side of the viewport
    top: 15%; // Start from the top of the viewport
    height: 85%; // Full height of the viewport
    transition: width 0.3s ease-in-out; // Animate the width change
    // transform: translateX(-100%); // Start off-screen
    z-index: 10;

    &.assignedExamOverviewExpanded {
        width: 90%;
    }
    &.assignedExamOverviewViewFolded {
        width: 10%;
    }
}

.assignedExamOverviewWidthToggle {
    @include baseView();
    @include h3();

    padding: $BASE_MARGIN;

    width: 100%;
    justify-content: flex-end;

    cursor: pointer;

    @include baseBorder();
    border-top: none;
    border-left: none;
    border-right: none;

    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $DARK_BROWN;
    }
}
