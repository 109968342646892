
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../assets/fonts.scss";

.sectExamView {
    @include baseView();
    @include h3();
    font-weight: $THIN;

    justify-content: space-between;

    height: 3rem;
    width: 100%;
    padding: $BASE_MARGIN;

    cursor: pointer;
}

.sectExamTitle {
    margin-right: $BASE_MARGIN*2;

    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
}

.sectExamView:hover {
    background-color: $BAIGE;
}

.sectSelectedExamView {
    background-color: $BAIGE;
}

.sectExtra {
    @include baseView();
    white-space: pre;
}

.sectStartExam {
    @include examsLink();
}

.sectStartExam:hover {
    @include examsLinkHover();
}
