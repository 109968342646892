
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.MultipleChoiceOption {
    display: flex;

    justify-content: space-between;

    @include baseBorder();
    border-style: none none solid none;

    padding: $BASE_MARGIN*2;

    width: 100%;

    @include h4();
    font-weight: $NORMAL;
}

.MultipleChoiceOptionError {
    border-color: $YELLOW;
}

.multipleChoiceClickable {
    cursor: pointer;
}

.FirstMultipleChoiceOption {
    padding-top: $BASE_MARGIN*2;
}

.LastMultipleChoiceOption {
    padding-bottom: $BASE_MARGIN*2;

    border-style: none none none none;
}

.CorrectMultipleChoiceOption {
    background-color: $LIGHT_GREEN;
}

.InCorrectMultipleChoiceOption {
    background-color: $LIGHT_RED;
}

.multipleChoiceSelected {
    color: $WHITE;
    background-color: $DARK_BROWN;
}

.MCOptionCheck {
    @include baseView();
    margin-right: $BASE_MARGIN;

    color: $DARK_BROWN;
}

.editMCAnswers {
    padding-top: $BASE_MARGIN*3;
}

.MultipleChoiceOptionContent {
    display: flex;
    width: 100%;

    margin-right: $BASE_MARGIN;
}

.optionText {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointsInputOption {

    width: 5%;

    min-width: $BASE_MARGIN*4;

    margin-top: $BASE_MARGIN*0.8;
    margin-right: $BASE_MARGIN*1.5;

}

.deleteMcOption:hover {
    color: $LIGHT_RED;

    cursor: pointer;
}
