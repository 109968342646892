
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";
@import "../../assets/constants.scss";

.shareContentView {
    @include baseView();
    @include baseCard();

    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    padding: $BASE_MARGIN;

    width: 100%;

    margin-bottom: $BASE_MARGIN;
}

.shareContentRow {
    @include baseView();
    width: 100%;

    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
}

.shareContentTitle {
    @include baseView();
    width: 100%;

    @include h4();
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    margin-bottom: $BASE_MARGIN;
}

.shareContentClickable {
    @include baseView();
    
    @include h4();
    font-weight: $NORMAL;
    
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    
    margin-bottom: $BASE_MARGIN/2;
    
    white-space: nowrap;
    display: inline-flex;
    }

.shareContentClickable:hover {
    cursor: pointer;
    color: $YELLOW;
}
