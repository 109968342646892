
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";
@import "../../assets/style.scss";

.feedbackButton {

    position: fixed;

    bottom: $BASE_MARGIN*2;
    right: $BASE_MARGIN*2;

    @include h4();

    padding: $BASE_MARGIN $BASE_MARGIN*2;

    @include baseCard();
    @include baseBorder();
    border-color: $WHITE;
    @include baseShadow();

    cursor: pointer;

    z-index: 100;

}

.feedbackButton:hover {

    color: $YELLOW;
    border-color: $YELLOW;

}
