
@import "../newSRC/assets/constants.scss";
@import "../newSRC/assets/fonts.scss";

.back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: $BAIGE;
}

.back-to-top:hover {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: $DARK_BROWN;
}

h2 {
  @include h2();
}

h3 {
  @include h3();
}

h4 {
  @include h4();
}

// li also defined in EntityView.scss!!!
p,
ul,
ol,
li {
  @include h4();
  font-weight: $THIN;
}
