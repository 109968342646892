
@import "../../assets/constants.scss";
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";

.assignmentView {

    @include baseView();

    flex-direction: column;

    padding: $VIEW_PADDING;
    padding-top: $BASE_MARGIN*2;

}

.loaderRapExamPrep {
    margin-top: $BASE_MARGIN;
}
