
@import "../../../assets/constants.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/style.scss";

.FeedbackField {

    // display: flex;
    // flex-direction: column;

    // padding: $BASE_MARGIN $BASE_MARGIN*2;
    // @include baseCard();

    @include addContent();
    
}

// .FeedbackField {
//     width: 100%;
// }

.FeedbackTitle {

    @include h3();

}

.feedbackSubTitle {

    width: 100%;

    @include h5();
    margin-top: $BASE_MARGIN;

}

.feedbackText {
    margin-bottom: $BASE_MARGIN;
}

.sendFeedback {
    
    justify-content: center;

    @include h4();
    font-weight: $THIN;

    @include baseCard();
    @include baseBorder();

    padding: $BASE_MARGIN $BASE_MARGIN*1.5;
    margin-bottom: $BASE_MARGIN*1.5;

    cursor: pointer;

}

.sendFeedback:hover {
    color: $YELLOW;
    border-color: $YELLOW;
}
