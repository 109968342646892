
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";

.CardStyling {
    @include baseCard();
    @include baseBorder();

    align-items: center;
    justify-content: flex-start;

    height: 12rem;
    width: 27rem;

    margin: 1rem;

    overflow: hidden;

    cursor: pointer;

}

.CardStyling:hover {
    @include baseShadow();
}

.imgCard {
    width: auto;
    height: calc(100% - $BASE_MARGIN*4);
    margin: 0rem $BASE_MARGIN*2 0rem $BASE_MARGIN*2;
}

.cardText {
    height: 100%;

    align-items: flex-start;

    overflow-y: scroll;

    padding: $BASE_MARGIN*2 $BASE_MARGIN*2 $BASE_MARGIN*2 0rem;
}

.CardTitle {
    @include h3();

    overflow-wrap: anywhere;
    color: $LIGHT_BROWN;
}

.CardDescipt {
    @include h4();
    color: $LIGHT_BROWN;

    overflow-wrap: anywhere;
    font-weight: $VERY_THIN;

    margin-top: $BASE_MARGIN/2;
}
