
@import '../../../../assets/constants.scss';
@import '../../../../assets/fonts.scss';
@import '../../../../assets/style.scss';

//For text boxes only
input[type="submit"] {
    
    @include h4();
    @include baseView();
    @include baseCard();
    @include baseBorder();
  
    background-color: $WHITE;
  
    margin: 0rem 0rem 0rem 0rem;
    padding: 0.4rem 0.5rem 0.4rem 0.5rem;
  
    min-width: 10rem;

    cursor: pointer;
  }
  
input[type="submit"]:hover {
    color: $YELLOW;
}
  
input[type="submit"]:active {    
    border-color: $YELLOW;
}
