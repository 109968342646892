
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.chatagoryTitle {
    @include baseView();
    @include h3();

    justify-content: flex-start;

    width: 100%;

    margin: $BASE_MARGIN*1.5 0rem $BASE_MARGIN/2 0rem;

}

.ChatagoryHolder {
    @include baseView();

    flex-direction: column;

    width: 100%;

    padding: $BASE_MARGIN;
}

.addTheme {
    @include baseView();
    margin-left: $BASE_MARGIN;
}
