
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

@import "../../../../../../components/input/assets/style.scss";

.MultipleChoice {
    @include baseView();
    align-items: flex-start;
    flex-direction: column;

    @include baseBorder();

    width: 100%;

    margin-bottom: $BASE_MARGIN;

}

.MultipleChoiceError {
    border-color: $YELLOW;
}

.MultipleChoiceWarning {
    @include showWarning();
}
