
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../assets/style.scss";

.ResultsView {
    @include baseView();
    justify-content: flex-start;
    align-items: flex-start;

    flex-direction: row;

    width: 100%;
}

.resultsOptions {
    @include baseView();
    flex-direction: column;

    margin: $BASE_MARGIN*2 0rem;
}

.resultsCards {
    @include holdPracticeCards();
}

.resultsFilter {
    @include SelectionsBase();
    
    padding: $BASE_MARGIN $BASE_MARGIN*2;
    margin-bottom: $BASE_MARGIN;
}

.resultsFilterItem {
    @include SelectionItemBase();
    justify-content: flex-start;
}

.resultsButton {
    @include SelectionsBase();
    @include h4();

    margin-bottom: $BASE_MARGIN;

    padding: $BASE_MARGIN;

    justify-content: center;
    align-items: center;
}

.resultsButton:hover {
    cursor: pointer;
    color: $YELLOW;
}
