
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.ExamPointsGraph {
    @include baseView();
    @include baseCard();

    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    margin-top: $BASE_MARGIN*2;
    padding: $BASE_MARGIN*2;

    @include h3();
    font-weight: $THIN;
}

.ExamPointsGraphTitle {
    @include baseView();
    @include h3();
}

.ExamPointsGraphFoldButton {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;

    cursor: pointer;

    margin-left: $BASE_MARGIN;

    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $DARK_BROWN;
    }
}


