
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

// local
@import "../../../../assets/style.scss";

.examPrepRowItem {
    @include examPrepRowItem();
}

.examPrepRowItem:hover {
    @include examPrepRowItemHover();
}

.examPrepRowItemTitle {
    @include examPrepRowItemTitle();
}

.examPrepRowItemSelected {
    @include examPrepRowItemSelected();
}
