
@import "../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../assets/style.scss";

$HEIGHT_GRADING_OVERVIEW_TOPBAR: 2.5rem;

@mixin overviewItemView {
    @include baseView();
    @include baseBorder();

    justify-content: space-between;
    border-radius: $BORDER_RADIUS;

    padding: $BASE_MARGIN;
    margin: $BASE_MARGIN;

    width: calc(100% - ($BASE_MARGIN*2));
}

@mixin overviewItemFoldedView {
    @include baseView();
    margin: $BASE_MARGIN/2 $BASE_MARGIN;
    flex-direction: column;

    cursor: pointer;

    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $DARK_BROWN;
    }
}

@mixin overviewItemSectionView {
    @include baseView();
    flex-direction: column;
}
