
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";
@import "../../assets/style.scss";

.ColumnListView {
    @include baseView();

    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
}

@mixin ColumnBase {
    @include baseView();
    @include baseContent();

    flex-direction: column;

    width: calc(50% - $BASE_MARGIN/4);
    align-items: flex-start;

    flex-wrap: wrap;
}

.LeftColumn {
    @include ColumnBase();
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;

    overflow: hidden;
}

.RightColumn {
    @include ColumnBase();
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
}

.listItem {
    @include baseView();
    width: 100%;
}
