
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";

.basicBooleanSelectView {
    @include baseView();
    @include h4();
    color: $LIGHT_BROWN;

    justify-content: flex-start;
    align-items: center;

    // height: 1.5rem;
    width: 100%;

    margin: $BASE_MARGIN/2 0rem;

    cursor: pointer;
}
