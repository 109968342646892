
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

// local
@import "./assets/style.scss";

.TextExplenation {
    @include baseView();

    align-items: flex-start;
    flex-direction: column;

    width: 100%
}

.titleTextExplenation {
    @include baseView();
    @include h2();

    align-items: flex-end;

    margin: $BASE_MARGIN*3 $BASE_MARGIN $BASE_MARGIN 0rem;
}

.addText {
    margin: 0rem 0rem 0rem $BASE_MARGIN;
}

.textExplenationHolder {
    @include textExplHolder();
}

.saveOrDelNewText {
    @include baseView();
    flex-direction: row;
    align-items: flex-end;
}
