
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.cardBannerView {
    @include baseView();
    @include h4();
    line-height: 0rem;

    justify-content: space-between;

    margin: 0rem $BASE_MARGIN 0rem $BASE_MARGIN;

    width: calc(100% - $BASE_MARGIN*2);
    height: 2rem;
}

.bannerLeft {
    justify-content: flex-end;
}

.cardResponses {
    @include baseView();

    justify-content: flex-start;
}

.correctResponse {
    color: $GREEN;
}

.wrongResponse {
    color: $RED;
}
