
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.questionTitle {
    margin: $BASE_MARGIN*2 0rem 0rem 0rem;
}

.questionHeader {
    display: flex;
    align-items: flex-end;
    
    @include h3();

    margin-bottom: $BASE_MARGIN;
}
