
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";

.Loader {

    @include h3();
    @include baseView();

}

.LoadWheel {
    // font-size: $h3FontSize;
    margin: 0em 0.1em 0em 0.1em;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(365deg);
    }
}
