
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.studentsView {
    @include baseView();

    padding-top: $BASE_MARGIN;

    width: 100%;

    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    margin-bottom: $BASE_MARGIN;
}
