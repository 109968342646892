
@import "../../../assets/style.scss";
@import "../../../assets/constants.scss";
@import "../../../assets/fonts.scss";

.StudentPerformancePlotButtonContainerView {
    @include baseView();
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.StudentPerformancePlotButtonsView {
    @include baseView();
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: $BASE_MARGIN $BASE_MARGIN*2 $BASE_MARGIN $BASE_MARGIN;
}
