
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/style.scss";
@import "../../../../../../assets/fonts.scss";

@import "../../../../assets/style.scss";

.assignmentDescriptionView {
    @include assignmentSectionColumns();
}

.assignmentDescription {
    @include baseView();
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    
    width: 100%;
    height: 100%;
}

.assignmentNoDescription {
    @include baseView();

    @include h4();
    font-weight: $THIN;

    justify-content: flex-start;
    width: 100%;
    height: 100%;
}
