
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";
@import "../../assets/style.scss";

.Checked {
    color: $DARK_BROWN;
    cursor: pointer;
}

.Checked:hover {
    color: $YELLOW;
}

.Checked:active {
    color: $DARK_BROWN;
}
