
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.subTopicTitleView {

    @include baseView();

    width: 100%;

    flex-direction: column;

    margin-bottom: $BASE_MARGIN*3;

}

.subTopicTitle {

    @include baseView();
    @include h2();

}

.subTopicSubTitle {

    @include baseView();
    @include h2();

}
