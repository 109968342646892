
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

// local
@import "../../../../../../assets/examStyles.scss";

.gradingAssignedExamView {
    @include baseExamView();
    width: 100% - 12%;

    margin-top: $BASE_MARGIN*2;
    margin-left: 12%;
}

.gradingAssignedExamTitle {
    @include baseView();
    @include h3();

    margin-top: $BASE_MARGIN*5;
}
