
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

// local
@import "../../assets/examStyles.scss";

.checkExam {
    @include baseExamView();

    margin-top: $BASE_MARGIN*2;
}
