
@import "../../../../../../../../../../assets/constants.scss";
@import "../../../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../../../assets/style.scss";

.SelectQuestionsView {
    @include baseView();
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}

.SelectQuestionsTitle {
    @include baseView();
    @include h3();
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.SelectQuestionsListQuestionsView {
    @include baseView();
    align-items: flex-start;
    justify-content: flex-start;
    // flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}

.SelectQuestionsViewBooleanSelectView {
    @include baseView();
    @include h4();
    color: $LIGHT_BROWN;

    justify-content: flex-start;
    align-items: center;

    // height: 1.5rem;

    margin: $BASE_MARGIN/2 $BASE_MARGIN $BASE_MARGIN/2 0;

    cursor: pointer;
}
