
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.AssignmentAccessView {
    @include baseView();

    flex-direction: column;

    padding: $VIEW_PADDING;
    // padding-top: $BASE_MARGIN*2;
}

.assignmentAccessInfoView {
    @include baseView();
    @include baseCard();

    flex-direction: column;

    padding: $BASE_MARGIN;

    width: 100%;
}

.assignmentAccessTitle {
    @include baseView();
    @include h3();
}

.assignmentAccessBody {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;
    margin: $BASE_MARGIN 0rem;
}

.assignmentAccessTitleLoader {
    margin: $BASE_MARGIN 0rem;
}
