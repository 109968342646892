
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

// local
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.sectExamPreview {
    @include sectExamTabView();
}

.overviewToView {
    @include examsLink();
    margin: 0rem;
}

.overviewToView:hover {
    @include examsLinkHover();
    margin: 0rem;
}
