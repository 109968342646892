
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/style.scss";
@import "../../../../../../../../assets/fonts.scss";

@import "../../../../../../assets/style.scss";

.AddAssignedItemsView {
    @include addContent();
}

.AddAssignedItemsTitle {
    @include h3();
    width: 100%;
    margin-bottom: $BASE_MARGIN;
}
