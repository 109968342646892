
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.checked {
    color: $YELLOW;
    cursor: pointer;

    margin: 0rem $BASE_MARGIN/2 0rem 0rem;
}

.checked:hover {
    color: $DARK_BROWN;
}

.unChecked {
    color: $DARK_BROWN;

    margin: 0rem $BASE_MARGIN/2 0rem 0rem;
}

.unChecked:hover {
    color: $YELLOW;
}
