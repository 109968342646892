
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";

.TextareaInputBaseTextField {
    @include h5();
    @include baseBorder();
    @include baseCard();
    
    width: calc(100% - $BASE_MARGIN);
}

.TextareaInputResizeBoth {
    resize: both; // Only allow vertical resizing
}

.TextareaInputResizeVertical {
    resize: vertical; // Only allow vertical resizing
}

.TextareaInputResizeHorizontal {
    resize: horizontal; // Only allow vertical resizing
}

.TextareaInputTextError {
    border-color: $RED !important;
}
