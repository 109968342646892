
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.StickyButtonsExamsHolder {
    position: sticky;
    top: 1rem;
    
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    padding-right: $BASE_MARGIN;

    width: calc(100% - $BASE_MARGIN);

    pointer-events: none;

    z-index: 50;

}
