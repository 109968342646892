
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.Links {
    @include baseView();

    align-items: flex-start;
    flex-direction: column;

    width: 100%
}

.LinksTitle {
    @include baseView();
    @include h2();

    margin: $BASE_MARGIN*3 $BASE_MARGIN $BASE_MARGIN 0rem;
}

.AddLinksList {
    margin: 0rem 0rem 0rem $BASE_MARGIN;
}

.loadLinks {
    padding: 0rem 0rem 0rem $BASE_MARGIN*0.75;
}

.noLinks {
    @include h3();
    font-weight: $THIN;
}
