
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/style.scss";
@import "../../../../../../assets/fonts.scss";

@import "../../../../assets/style.scss";

.assignmentSettingsView {
    @include assignmentSectionColumns();
}

.assignmentSettingsTitle {
    @include assignmentSectionTitle();
}

.assignmentShareWithStudents {
    @include baseView();
    @include h4();
    font-weight: $NORMAL;
    cursor: pointer;

    &:hover {
        color: $YELLOW;
    }
    &:active {
        color: $DARK_BROWN;
    }
}
