
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/constants.scss";

// local
@import "../../assets/style.scss";

.numberEditableView {
    position: absolute;
    display: inline;
    text-align: right;

    background-color: $WHITE;
    padding: 0rem $BASE_MARGIN 0rem $BASE_MARGIN;

    margin-top: $BASE_MARGIN*3;
}

.numberEditableOption {
    cursor: pointer;
    
    padding-bottom: $BASE_MARGIN/2;
}

.numberEditableOption:hover {
    color: $YELLOW;
}
