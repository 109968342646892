
@import "../../assets/style.scss";
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";

.errorView {

    @include baseView();
    @include baseBorder();
    @include baseContent();
    @include baseShadow();

    justify-content: flex-start;
    flex-direction: column;

    position: fixed;
    z-index: 300;
    bottom: $BASE_MARGIN;
    left: $BASE_MARGIN;

    width: 25rem;
    max-width: 95%;
    min-height: 8rem;

}

.GlobalErrorsTitle {
    @include baseView();
    @include h4();

    justify-content: space-between;
    
    color: $YELLOW;

    width:  100%;

    padding: $BASE_MARGIN;

}

.GlobalErrorsBody {
    @include baseView();

    justify-content: flex-start;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    height: 100%;

    padding: $BASE_MARGIN $BASE_MARGIN*2;
}

.globalError {
    @include h5();

    margin: $BASE_MARGIN 0rem;

    width: 100%;
}

.lineErrorTitle {
    @include baseLine();
}
