
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.holderExamPrep {
    width: 100%;
}

.topicExamPrep {

    @include baseContent();

    margin: $BASE_MARGIN 0rem;
    padding: $BASE_MARGIN;

    width: 100%;

    justify-content: space-between;

    cursor: pointer;
    
}

.topicExamPrep:hover {
    color: $YELLOW !important;
}

.topicTitleExamPrep {

    @include h3();

}

.extraMarginTopExamPrep {
    margin-top: $BASE_MARGIN*2;
}

.pointerHolderExamPrep {

    margin-right: $BASE_MARGIN;

}

.topicChildExamPrep {
    @include baseContent();

    margin-bottom: $BASE_MARGIN;

    overflow: hidden;
}

.topicChildChildExamPrep {
    @include baseView();
    width: 50%;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    overflow: hidden;
}

.examPrepLoader {
    padding: $BASE_MARGIN;
}

.lineExamPrep {

    border-right-style: solid;
    border-right-width: $BORDER_WIDTH;
    border-right-color: $DARK_BROWN;

}

.ExamPrepNoExamsWarning {
    @include baseView();
    @include h3();

    font-weight: $THIN;

    padding: $BASE_MARGIN;

    cursor: default;
}

.examPrepPageAddSubTopic {
    @include baseCard();
    @include baseBorder();

    @include h4();
    font-weight: $THIN;
    
    margin: $BASE_MARGIN;
    padding: $BASE_MARGIN/2 $BASE_MARGIN*3;

    cursor: pointer;
}

.examPrepPageAddSubTopic:hover {
    color: $YELLOW;
    border-color: $YELLOW;
}

.examPrepPageAddSubTopic:active {
    border-color: $BORDER_COLOR;
}
