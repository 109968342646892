
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

.EtAliaView {
    @include baseView();

    padding-top: $BASE_MARGIN*2;

    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
}

.EtAliaImages {
    @include baseView();
    flex-direction: column;
    
    align-items: flex-start;

    width: 19%;
}

.EtAliaText {
    width: 79%;
}

.imgEtAlia {

    @include baseShadow();

    width: 100%;
    height: auto;

    border-radius: $BORDER_RADIUS;

    margin: $BASE_MARGIN*2 0rem;
}
