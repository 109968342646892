
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.question {
    @include baseCard();
    flex-direction: column;

    @include baseShadow();

    @include baseBorder();
    border-color: $WHITE;

    width: 100%;
    
    padding: $BASE_MARGIN*2;
    margin: $BASE_MARGIN*2 0rem;
}

.questionError {
    @include baseBorder();
    
    border-color: $YELLOW;
}

.questionAnswers {
    margin: $BASE_MARGIN*2 0rem 0rem 0rem;
}

.editMCButton {

    display: flex;

    @include h4();
    font-weight: $THIN;

    margin: 0rem 0rem $BASE_MARGIN/2 $BASE_MARGIN;

    cursor: pointer;

}

.editMCButton:hover {
    color: $YELLOW;
}

.editMCButton:active {
    color: $DARK_BROWN;
}
