
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/style.scss";
@import "../../../../../../assets/fonts.scss";

@import "../../../../assets/style.scss";

.assignmentOverviewView {
    @include assignmentSectionColumns();
}

.assignmentOverviewTitle {
    @include assignmentSectionTitle();
}
