
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.exclamationTriangle {
    @include baseView();
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0rem $BASE_MARGIN $BASE_MARGIN/2 0rem;
}
