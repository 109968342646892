
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.Edit {
    color: $DARK_BROWN;
    cursor: pointer;

    margin: 0rem $BASE_MARGIN/2 0rem $BASE_MARGIN/2;
}

.Edit:hover {
    color: $YELLOW;
}

.Edit:active {
    color: $DARK_BROWN;
}
