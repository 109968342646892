
@import "./constants.scss";
@import "./style.scss";
@import "./fonts.scss";

.whiteRoundedView {
    @include baseView();
    @include baseCard();
    width: 100%;

    margin: $BASE_MARGIN*2;
    padding: $BASE_MARGIN*2;
}

.titleView {
    @include h2();
    margin: 0 0 $BASE_MARGIN*2 0;
    width: 100%;
}

.subTitleView {
    @include h3();
    margin: 0 0 $BASE_MARGIN 0;
    width: 100%;
}

.columnView {
    @include baseView();
    width: 100%;

    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
}

.rowView {
    @include baseView();

    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row;
}

.fullWidth {
    width: 100%;
}
