
$WHITE: #FFFFFF;
$BAIGE: #EDE8E2;
$YELLOW: #BE773A;
$RED: #be3a3a;
$LIGHT_RED: #f86161;
$DARK_BROWN: #574A3F;
$LIGHT_BROWN: #6C6C6C;
$GREEN: #008000;
$LIGHT_GREEN: #a4eca4;
$LINK_COLOR: #008000; // green;

// View
$VIEW_PADDING: 3rem;

// border constants
$BORDER_COLOR: #C4C4C4;
$BORDER_WIDTH: 0.07rem;
$BORDER_RADIUS: 0.5rem;

// fonts
$FONT_TYPE: 'Montserrat';

$H2_SIZE: 26px;
$H3_SIZE: 18px;
$H4_SIZE: 15px;
$H5_SIZE: 12px;
$FOOTER_SIZE: 10px;

$H4_LINE_HEIGHT: 1.2;
$H5_LINE_HEIGHT: 1.1;

$BOLD: 700;
$NORMAL: 500;
$THIN: 400;
$VERY_THIN: 300;

// shadow
$SHADOW_ALIGN: 0px 3px 3px 0px;
$SHADOW_COLOUR: rgba(129, 127, 125, 0.28);

$BASE_MARGIN: 0.5rem;

// z index's
$FRONT: 100;
