
@import "../../assets/constants.scss";
@import "../../assets/style.scss";
@import "../../assets/fonts.scss";

.InfoItemView {
    @include baseView();

    width: 100%;

    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
}

.InfoItemMarginBottom {
    margin-bottom: $BASE_MARGIN/2;
}

.InfoItemTitle {
    @include h4();
    margin-right: $BASE_MARGIN;
    font-weight: $NORMAL;
}

.InfoItemValue {
    @include h4();
    font-weight: $THIN;
}
