
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.questionPoints {
    @include baseView();
    align-items: flex-end;
    justify-content: flex-start;

    flex-direction: column;
}

.holdPointsOnLine {

    display: flex;

}

.pointsStyle {
    margin: 0rem $BASE_MARGIN 0rem 0rem;
}

.pointsStyleEditable {
    margin: $BASE_MARGIN*0.7 $BASE_MARGIN 0rem 0rem;
}

.questionPointsHover {
    cursor: pointer;
}

.questionPointsHover:hover {
    color: $YELLOW;
}
