
@import "../../../../assets/constants.scss";
@import "../../../../assets/style.scss";
@import "../../../../assets/fonts.scss";

// local
@import "../../assets/fonts.scss";
@import "../../assets/constants.scss";

@mixin baseUserButton {
    @include baseView();
    @include fontUser();

    height: 4rem;
    margin: $BASE_MARGIN*2 $BASE_MARGIN*3;

    min-width: 15rem;

    padding: $BASE_MARGIN*2;

    border-radius: $BORDER_RADIUS;

    background-color: $DARK_BROWN;

    cursor: pointer;
}
.UserDropdown{
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: $DARK_BROWN;

    border-radius: 0 0 $BORDER_RADIUS $BORDER_RADIUS;
}

.UserDropdownItem{
    
    @include baseView();
    @include fontUser();
    text-decoration: none;
    display: block;
    color: #fff;
    padding: $BASE_MARGIN*2;
    border-radius: $BORDER_RADIUS;

    &:hover {
        color: $YELLOW;
        text-decoration: none;
    }
    &:active {
        color: $WHITE;
        text-decoration: none;
    }
}

.UserButton {

    @include baseUserButton();

    justify-content: flex-start;
    position: relative;

    z-index: $FRONT;

}

.UserButtonUnfolded {
    border-radius: $BORDER_RADIUS $BORDER_RADIUS 0 0;

}


.UserButton:hover {

    background-color: $LIGHT_BROWN;

}

#dropdown-basic{
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
}
.UserButton .dropdown{
    width: 100%;
    position: unset;
    height: 100%;;
}

.UserButton .dropdown-menu{
    background-color: $DARK_BROWN;
    color: #fff;
    // left: -16px !important;
  width: 100%;
  padding: 0;
}

.UserButton .dropdown-menu img{
    float: left;
    display: inline;
}

.UserButton .dropdown-menu a{
    color: #fff;
    padding-top: 15px;
  padding-bottom: 15px;
}

.nameHolder {
    @include baseView();
    @include fontUser();

    width: 100%;

    justify-content: center;
}

.UserLogout {

    @include baseUserButton();
    justify-content: space-around;

}

.UserLogout:hover {

    background-color: $LIGHT_BROWN;

}

.vectorUser {
    height: $NAV_ITEM_HEIGHT;
    width: auto;

    margin-right: 0.5rem;
}

.logOutButton {
    @include fontUser();
}

.logOutButton:hover {
    text-decoration: underline;
}

.logOutButton:active {
    color: $BAIGE;
    text-decoration: underline;
}


@media (max-width: 800px) {

    .UserButton {
        min-width: 10rem;
    }

    .UserLogout {
        min-width: 10rem;
    }

    .vectorUser {
        display: none;
    }
}
