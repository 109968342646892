
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";
@import "../../../../assets/style.scss";

.loaderRapExamPrep {
    margin-top: $BASE_MARGIN;
}

.personalInfoView {
    @include baseView();
    flex-direction: row;
    align-items: start;
  
    width: 100%;
    padding-top: $BASE_MARGIN*3;
    background-color: $WHITE;
    margin-top: $BASE_MARGIN;
    border-radius: $BORDER_RADIUS;
    padding-bottom: $BASE_MARGIN*3;
    padding-right: $BASE_MARGIN;
}

.personalInfoView .profile-pic{
  flex: 0 0 035%;
  max-width: 250px;
  text-align: center;
}
.personalInfoView .profile-form{
  flex-grow: 1;
}
.personalInfoView .form-container{
  max-width: 500px;
}

.textLight{
  font-weight: lighter;
}


.personalInfoView 
.submit {
    align-items: start;
    justify-content: start;
}

.submitButtonView {
  margin-right: $BASE_MARGIN;
}

@media(max-width:650px) {
  .personalInfoView .profile-pic{
    margin: auto;
  }
  .personalInfoView{
    flex-direction: column;
  }
  .profile-form{
    width: 100%;
  }
  .personalInfoView .form-container {
    margin: auto;
  }
}

.resetUserEmails {
  @include baseView();
  &:hover {
      color: $YELLOW;
      cursor: pointer;
  }
  &:active {
    color: $DARK_BROWN;
  }
}
