
@import "../../assets/style.scss";
@import "../../assets/constants.scss";
@import "../../assets/fonts.scss";

.Alert {
    @include addContent();
}

.AlertButtons {
    
    display: flex;
    justify-content: space-around;

    width: 100%;

    margin-top: $BASE_MARGIN*2;
}
