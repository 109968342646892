
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";

.AlertButton {
    @include baseView();
    flex-wrap: wrap;

    @include h4();
    font-weight: $THIN;

    cursor: pointer;

    width: 30%;

    margin: 0 0.5% 0 0.5%;
    text-decoration: underline;

    &:active {
        text-decoration: none;
    }
}

.AlertButton:hover {
    color: $YELLOW;
}
