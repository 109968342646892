
@import "../../../../../../../../assets/constants.scss";
@import "../../../../../../../../assets/fonts.scss";
@import "../../../../../../../../assets/style.scss";

.feedbackRestorationView {
    @include baseView();
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;

    @include h4();
    font-weight: $THIN;

    margin-bottom: $BASE_MARGIN;
    // padding: $BASE_MARGIN $BASE_MARGIN;

    width: 100%;
}

.feedbackRestorationOptions {
    @include baseView();

    flex-direction: row;
}

.feedbackRestorationOption:hover {
    color: $YELLOW;
    cursor: pointer;
}

.feedbackRestorationOptionLeft {
    @include baseBorder();
    border-top: none;
    border-left: none;
    border-bottom: none;
    padding-right: $BASE_MARGIN;
}

.feedbackRestorationOptionRight {
    padding-left: $BASE_MARGIN;
    margin-right: $BASE_MARGIN;
}
