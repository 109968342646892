
@import "../../../assets/style.scss";
@import "../../../assets/fonts.scss";
@import "../../../assets/constants.scss";

// local
@import "../assets/style.scss";

.dateTimeView {
    @include baseView();
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    margin: $BASE_MARGIN/2 0rem;
}

.dateTimeInputAndErrorView {
    @include baseView();
    width: 80%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.dateTimeInputView {
    @include baseView();
    width: 80%;
    flex-direction: row;
    justify-content: flex-start;
}

.dateTimeTitle {
    @include h4();
    margin-right: $BASE_MARGIN;
    font-weight: $NORMAL;
}

.dateTimeNumberInput {
    box-shadow: none !important;
}

.dateTimeCancle {
    @include baseView();
    @include h4();

    font-weight: $THIN;

    cursor: pointer;

    margin: $BASE_MARGIN/2;
    white-space: nowrap;

    &:hover {
        color: $YELLOW;
    }

    &:active {
        text-decoration: underline;
    }
}
