
@import "../../../../../assets/style.scss";
@import "../../../../../assets/constants.scss";
@import "../../../../../assets/fonts.scss";

// .sectExamTabView

@mixin sectExamTabView {
    @include baseView();

    width: 100%;

    justify-content: flex-start;
    align-items: flex-start;
    
    flex-direction: column;

    padding: $BASE_MARGIN;
}
