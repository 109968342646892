
@import "../../../../../../assets/constants.scss";
@import "../../../../../../assets/fonts.scss";
@import "../../../../../../assets/style.scss";

.takenExam_TakenExam {

    margin: $BASE_MARGIN*0.5 0rem;

    @include h3();
    font-weight: $NORMAL;

    color: $DARK_BROWN !important;

    cursor: pointer;
    
}

.takenExam_TakenExam:hover {

    color: $YELLOW !important;
    text-decoration: underline !important;

}

.takenExam_TakenExam:active {

    color: $DARK_BROWN !important;

}
