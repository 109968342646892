
@import "../../../../assets/style.scss";
@import "../../../../assets/constants.scss";
@import "../../../../assets/fonts.scss";

// style
@import "../../../../assets/constants.scss";

.selectItem {
    @include h5();
    @include baseContent();
    @include baseBorder();

    width: 100%;

    cursor: pointer;
    
}

.selectItem:hover {
    border-color: $YELLOW;
}

.selectItemError {
    @include h5();
    @include baseContent();
    @include baseBorder();

    border-color: $RED;

    width: 100%;

    cursor: pointer;
    
}

.selectItemError:hover {
    border-color: $YELLOW;
}
